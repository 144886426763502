import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Program1 from 'assets/Employed/Program/program1.avif';
import { GlobalContext } from 'App';
import { ReactComponent as Click } from "assets/Employed/click.svg";

const SecondMo = () => {
    const { isTablet } = useContext(GlobalContext);
    const [isClick, setIsClick] = useState<boolean>(false);
    return (
        <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
            <motion.p
                className={`text-2xl text-black font-extrabold px-4`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                프로그램 소개
            </motion.p>

            <motion.p
                className={`text-base text-left leading-7 text-black font-medium px-4`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                제약바이오산업연구소는 제약바이오 산업의 이해를 바탕으로 고객사의 교육니즈와 문제를 이해하고 이를 해결할 수 있는 솔루션을 제공합니다.<br />
                이를 위해 의약품 전주기의 전문영역에 대해 관련 분야의 전문가들과 함께 교육 프로그램을 제공합니다.
            </motion.p>

            <motion.div
                className={`bg-[#F9F9F9] py-4.5 w-full flex flex-col gap-2 px-4`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                <p className={`${isTablet ? 'text-base text-left' : 'text-22'} text-black font-bold`}>1. 핵심 단계 목적 이해</p>
                <p className={`${isTablet ? 'text-base text-left' : 'text-22'} text-black font-bold`}>2. 핵심 단계 주요 역량과 기준 학습</p>
                <p className={`${isTablet ? 'text-base text-left' : 'text-22'} text-black font-bold`}>3. Case study 중심의 문제 해결 능력 강화</p>
                <p className={`${isTablet ? 'text-base text-left' : 'text-22'} text-[#0D0D93] font-bold`}>4. 학습과 산업현장의 연계 : 학습내용의 실전훈련을 위한 현장 학습</p>
            </motion.div>

            <div className=''>
                <motion.div
                    className="w-full relative"
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    {
                        !isClick &&
                        <div
                            className='absolute top-0 left-0 w-full h-full bg-[#00000099] flex items-center justify-center'
                            onClick={() => setIsClick(true)}>
                            <Click />
                        </div>
                    }

                    <div className='w-screen overflow-x-scroll p-4'>
                        <img src={Program1} alt="Program1" className='max-h-[507px] max-w-base' />
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default SecondMo;

