import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className='flex justify-center'>
            <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base text-left leading-7' : 'text-xl text-center leading-10'} text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의 인허가 교육 프로그램은 제약 및 바이오 산업 종사자들이 필수적으로 알아야 할<br />
                    인허가 절차와 규제 요구사항을 체계적으로 학습할 수 있도록 설계된 전문 과정입니다.<br />
                    제약 산업의 이해와 의약품 개발의 초기 단계부터 사후 관리까지의 전 과정을 다루며,<br />
                    국내외 규제기관의 최신 가이드라인을 반영하여 실무에 바로 적용할 수 있는 실질적인 지식을 제공합니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

