import React, { Fragment } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { ReactComponent as Arrow } from "assets/Intro/arrow.svg";
import ContactBtn from 'components/Common/ContactBtn';
import Manage1Mo from 'assets/Intro/manage1_mo.avif';
import Manage2Mo from 'assets/Intro/manage2_mo.avif';
import Manage3Mo from 'assets/Intro/manage3_mo.avif';
import Manage4Mo from 'assets/Intro/manage4_mo.avif';
import Manage5Mo from 'assets/Intro/manage5_mo.avif';

const BottomMo = () => {
    const data = [
        {
            title: '1. 혁신 & 창의성\n(Innovation & Creativity)',
            description: '우리는 혁신과 창의성을 바탕으로 한 교육을 지향합니다. 끊임없는 연구와 학습을 통해 최신 바이오 기술과 교육 방법을 개발하고, 이를 학생들에게 제공하여 그들의 잠재력을 최대한 발휘할 수 있도록 합니다.',
            img: Manage1Mo,
        },
        {
            title: '2. 학생 중심의 교육\n(Student - Centered Education) ',
            description: '학생의 성장과 발전이 우리의 최우선 과제입니다. 우리는 학생들의 개별적인 필요와 목표를 존중하며, 맞춤형 교육 프로그램을 제공하여 각자의 잠재력을 최대한으로 끌어올립니다.',
            img: Manage2Mo,
        },
        {
            title: '3. 실무 중심의 학습\n(Practice - Oriented Learning) ',
            description: '이론과 실무를 결합한 교육을 통해 학생들이 실제 연구 현장에서 필요한 능력을 배양할 수 있도록 합니다.현장 경험을 중시하며, 이를 통해 학생들이 현실 세계에서 문제를 해결할 수 있는 역량을 키웁니다.',
            img: Manage3Mo,
        },
        {
            title: '4. 지속 가능성과 사회적 책임 (Sustainability and Social Responsibility)',
            description: '지속 가능한 발전을 지향하며, 사회적 책임을 다하는 교육을 실천합니다. 환경과 사회적 문제에 대한 인식을 높이고, 이를 해결하기 위한 창의적이고 혁신적인 방법을 모색합니다. 학생들이 사회적 책임을 다하는 인재로 성장할 수 있도록 교육합니다.',
            img: Manage4Mo,
        }
    ]

    return (
        <Fragment>
            <div className='flex flex-col gap-18 items-center mb-18'>
                {
                    data.map((item, idx) => (
                        <Container className='flex flex-col items-center px-4 gap-8' key={idx}>
                            <motion.div
                                initial={START_ANIMATION_PROPERTY}
                                whileInView={END_ANIMATION_PROPERTY}
                                viewport={VIEWPORT_PROPERTY}>
                                <img src={item.img} alt={`manage1`} />
                            </motion.div>

                            <motion.div className='flex-1 flex flex-col gap-8 bg-[#FFFFFFCC] p-[1rem]'
                                initial={START_ANIMATION_PROPERTY}
                                whileInView={END_ANIMATION_PROPERTY}
                                viewport={VIEWPORT_PROPERTY}>
                                <p className='text-2xl text-center text-black font-bold whitespace-pre-line'>{item.title}</p>
                                <p className='text-base text-black font-medium text-center whitespace-pre-line'>{item.description}</p>
                            </motion.div>
                        </Container>
                    ))
                }
            </div>

            <div
                className={'w-full bg-cover py-14 px-4'}
                style={{ backgroundImage: `url(${Manage5Mo})` }}>
                <Container className='flex flex-col items-center gap-10'>
                    <motion.div
                        className='flex flex-col gap-8'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <p className='text-2xl text-center text-white font-bold whitespace-pre-line'>
                            5. 평생 학습과 성장 (Lifelong Learning and Growth)
                        </p>
                        <p className='text-base text-white font-medium text-center whitespace-pre-line'>
                            제약바이오산업연구소는 평생 학습의 중요성을 강조하며, 학생들이 졸업 후에도 지속적으로 성장하고 발전할 수 있는 교육 환경을 제공합니다.끊임없는 자기 개발과 학습을 통해 변화하는 세상에 적응하고, 새로운 기회를 창출할 수 있도록 지원합니다.
                        </p>
                    </motion.div>

                    <motion.div
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <Arrow />
                    </motion.div>

                    <ContactBtn />
                </Container>
            </div>
        </Fragment>
    );
}

export default BottomMo;

