import React, { useContext } from 'react';
import Header from 'components/Header/Header';
import StartupInner from 'components/StartupInner/StartupInner';
import { GlobalContext } from 'App';

const Startup = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <main className="bg-white">
            <Header />
            <div className={`${isTablet ? 'h-[50px]' : 'h-[120px]'}`}></div>
            <StartupInner />
        </main>
    );
}

export default Startup;