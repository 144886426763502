import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className='flex justify-center'>
            <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base text-left leading-7' : 'text-xl text-center leading-10'} text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 GMP(Good Manufacturing Practice) 표준을 기반으로 한 전문 교육 프로그램을 제공합니다.<br />
                    GMP는 제약, 바이오테크, 의료기기 등의 산업에서 품질을 보장하고 규제 요구 사항을 준수하기 위한 필수적인 기준입니다.<br />
                    우리의 GMP 컨설팅은 고객이 이러한 표준을 충족하고, 더 나아가 뛰어난 품질 관리 시스템을 구축할 수 있도록 돕습니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

