import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from 'App';

const Nav = () => {
    const fullLocation = useLocation();
    const { isTablet } = useContext(GlobalContext);
    const nav = [
        { url: '/employed/program', title: '의약품 전주기', titleEn: 'program' },
        { url: '/employed/develop', title: '연구개발', titleEn: 'develop' },
        { url: '/employed/clinical', title: '임상 & 비임상', titleEn: 'clinical' },
        { url: '/employed/license', title: '인허가', titleEn: 'license' },
        { url: '/employed/price', title: '의약품 약가', titleEn: 'price' },
        { url: '/employed/gmp', title: 'GMP', titleEn: 'gmp' },
        { url: '/employed/slaes', title: 'Slaes & Marketing', titleEn: 'slaes' },
    ]

    const liStyle = (titleEn: string) => {
        return `py-6.25 w-[188px] 
                ${fullLocation.pathname.includes(titleEn) ? 'bg-[#0D0D93]' : 'bg-white'} 
                border-b-4 
                ${fullLocation.pathname.includes(titleEn) ? 'border-b-[#0D0D93]' : 'border-b-[#E5E5E5]'}`
    }

    const liStyleMo = (titleEn: string, idx: number) => {
        return `py-[1rem] w-full 
                ${idx === 0 && 'col-span-2'} 
                ${fullLocation.pathname.includes(titleEn) ? 'bg-[#0D0D93]' : 'bg-white'} 
                border-1
                ${fullLocation.pathname.includes(titleEn) ? 'border-[#0D0D93]' : 'border-[#E5E5E5]'}`
    }

    return (
        <Container className='relative'>
            {
                isTablet ?
                    <>
                        <motion.ul
                            className={`grid grid-cols-2`}
                            transition={{ delay: 0.1 }}
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}>
                            {
                                nav.map((item, idx) => (
                                    <li className={liStyleMo(item.titleEn, idx)} key={idx}>
                                        <Link to={item.url}>
                                            <p className={`${fullLocation.pathname.includes(item.titleEn) ? 'text-white' : 'text-black'} font-semibold text-center`}>{item.title}</p>
                                        </Link>
                                    </li>
                                ))
                            }
                        </motion.ul>
                    </> :
                    <motion.div
                        className='absolute top-[-37px] '
                        transition={{ delay: 0.1 }}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <ul className={`flex items-center justify-between`}>
                            {
                                nav.map((item, idx) => (
                                    <li className={liStyle(item.titleEn)} key={idx}>
                                        <Link to={item.url}>
                                            <p className={`${fullLocation.pathname.includes(item.titleEn) ? 'text-white' : 'text-black'} text-center font-semibold`}>{item.title}</p>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </motion.div>
            }
        </Container>
    );
}

export default Nav;

