import React, { useContext } from 'react';
import Header from 'components/Header/Header';
import NewsInner from 'components/NewsInner/NewsInner';
import { GlobalContext } from 'App';

const News = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <main className="bg-white">
            <Header />
            <div className={`${isTablet ? 'h-[50px]' : 'h-[120px]'}`}></div>
            <NewsInner />
        </main>
    );
}

export default News;