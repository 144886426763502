import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);

    return (
        <Container className='flex justify-center px-4'>
            <div className='flex flex-col items-center justify-center gap-6 py-30'>
                <motion.p
                    className={`${isTablet ? 'text-2xl text-center' : 'text-32 text-left'} text-black font-bold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    미래의 생명과학 리더를 양성하는 혁신 교육의 중심
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-xl'} text-black font-medium text-center leading-7`}
                    transition={{ delay: 0.1 }}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 차세대 생명과학 인재를 양성하기 위해 혁신적인 교육 프로그램과{!isTablet && <br />}
                    연구 기회를 제공하는 선도적인 교육 기관입니다. 우리의 목표는 학생들이 최첨단 바이오 기{!isTablet && <br />}
                    술과 학문적 성과를 통해 인류의 건강과 행복에 기여할 수 있도록 돕는 것입니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

