import React from 'react';
import First from './First';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';
import Fifth from './Fifth';
import Sixth from './Sixth';

const Introduce = () => {
    return (
        <section id="intro_introduce">
            <First />
            <Second />
            <Third />
            <Fourth />
            <Fifth />
            <Sixth />
        </section>
    );
}

export default Introduce;

