import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import Advisory from 'components/Intro/Advisory/Advisory';
import Greetings from 'components/Intro/Greetings/Greetings';
import Introduce from 'components/Intro/Introduce/Introduce';
import useIntersectionObserver from 'hook/useIntersectionObserver';
import Management from 'components/Intro/Management/Management';
import { GlobalContext } from 'App';

const Intro = () => {
    const fullLocation = useLocation();
    const location = fullLocation.pathname.split('/').pop();
    const [isWhite, setIsWhite] = useState(false);
    const firstRef = useRef<any>(null);
    const { isTablet } = useContext(GlobalContext);
    useEffect(() => {
        location === 'introduce' ? setIsWhite(true) : setIsWhite(false)
        firstRef.current = document.getElementById('intro_introduce_first') as HTMLElement;
    }, [location])

    useIntersectionObserver({
        target: firstRef,
        threshold: 0.1,
        enabled: true,
        onIntersect: () => setIsWhite(true),
        offIntersect: () => setIsWhite(false),
    });
    return (
        <main className="bg-white">
            <Header isWhite={isWhite} />
            {
                location !== 'introduce' &&
                <div className={`${isTablet ? 'h-[50px]' : 'h-[120px]'}`}></div>
            }
            {
                location === 'introduce' ?
                    <Introduce /> :
                    location === 'management' ?
                        <Management /> :
                        location === 'greetings' ?
                            <Greetings /> :
                            <Advisory />
            }
        </main>
    );
}

export default Intro;