import React, { Fragment, useCallback, useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const Third = () => {
    interface Data {
        title: string,
        contents: string[]
    }

    const { isTablet } = useContext(GlobalContext);

    const data1: Data[] = [
        {
            title: '기본 개념', contents: ['제약바이오 산업 개요 : 제약바이오의 정의, 역사, 최신 동향', '임상과 비임상의 차이: 정의, 목적, 중요성 비교', '의약품 개발 프로세스: 기초연구, 비임상시험, 임상시험, 시판 후 단계']
        },
        {
            title: '비임상 교육', contents: ['비임상시험 설계 및 규제: GLP(우수실험실관리기준), 비임상시험 설계, 데이터 관리', '비임상시험 종류: 약리학적 시험, 독성시험, ADME(흡수, 분포, 대사, 배설)', '동물실험 윤리: 동물 복지, 윤리적 고려사항, 3R 원칙(Replacement, Reduction, Refinement)', '비임상 데이터 해석: 데이터 분석, 리포트 작성 및 규제기관 제출']
        },
        {
            title: '임상 교육', contents: ['임상시험 개요: 임상시험 단계(1상, 2상, 3상, 4상), 각 단계의 목적과 절차', '임상시험 설계: 무작위배정, 대조군 설정, 맹검법 등 설계 전략', '임상시험 규제: ICH GCP, IRB 승인, 규제기관의 역할(FDA, EMA 등)', '데이터 관리 및 분석: CRF 설계, 데이터 품질 관리, 통계적 분석 방법', '임상시험 사례 연구: 실제 임상시험 사례 분석 및 교훈']
        },
        {
            title: '실습 및 워크샵', contents: ['가상 임상시험 설계 워크샵: 참가자들이 직접 임상시험을 설계하고 시뮬레이션해보는 경험', '비임상 데이터 분석 실습: 실제 비임상 데이터를 이용해 분석 및 보고서 작성 실습', '규제 제출 문서 작성: IND / NDA 제출 문서 작성 및 규제기관 대응 전략 학습']
        },
    ]

    const DataGrid = useCallback(({ data }: any) => (
        <motion.div
            className={`grid ${isTablet ? '' : 'grid-cols-[1fr_2.3fr]'}  gap-x-1 gap-y-1.5 mb-[1rem]`}
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={{ once: true, amount: 0.1 }}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        <p className={`${gridInnerStyle(0)}} border-b-[#D9E2F4] text-left text-xl font-bold 
                        ${isTablet ?
                                'bg-[#F1F6FA] px-4 text-base py-2' :
                                'bg-[#F7F8FF] border-b-4 text-xl justify-center py-6'
                            }`}>
                            {item.title}
                        </p>
                        <div className={`${gridInnerStyle(1)} border-b-[#E3EDF4] border-b-4 flex-col 
                        ${isTablet ?
                                'px-4 py-2 text-sm' :
                                'px-8 py-6'
                            }`}>

                            {
                                item.contents.map((items, idxs) => (
                                    <motion.p
                                        key={idxs}
                                        className={`text-left font-semibold`}
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}>
                                        · {items}
                                    </motion.p>
                                ))
                            }
                        </div>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])

    const gridInnerStyle = (num: number) => {
        return `flex ${num === 0 ? 'items-center' : 'items-start'}  whitespace-pre-line text-black `
    }

    const gridTitleStyle = () => {
        return `bg-[#DBE7F9] border-b-4 border-b-[#C8D0E5] text-xl p-1.5 text-black font-semibold`
    }

    return (
        <Container className={`flex flex-col ${isTablet ? 'gap-6' : 'gap-15'}`}>
            <motion.p
                className={`${isTablet ? 'text-2xl leading-10' : 'text-48'} text-black font-extrabold text-left`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                임상 & 비임상 교육{isTablet && <br />} 커리큘럼
            </motion.p>

            <div>
                {
                    isTablet ?
                        <motion.div
                            className='mb-[1rem]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <p className={`${gridTitleStyle()} text-left ${isTablet ? 'pl-4 py-4' : 'pl-8'}`}>교과목명 / 학습내용</p>
                        </motion.div> :
                        <motion.div
                            className='grid grid-cols-[1fr_2.3fr] gap-x-1 mb-[1rem]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <p className={`${gridTitleStyle()} text-center`}>교과목명</p>
                            <p className={`${gridTitleStyle()} text-left pl-8`}>학습내용</p>
                        </motion.div>
                }

                <DataGrid data={data1} />

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>

                <motion.div
                    className={isTablet ? 'py-18' : 'py-44'}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </div>

        </Container>
    );
}

export default Third;
