import React from 'react';
import First from './First';
import Second from './Second';
import Third from './Third';

const Greetings = () => {
    return (
        <section id="intro_greetings">
            <First />
            <Second />
            <Third />
        </section>
    );
}

export default Greetings;

