import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header/Header';
import useIntersectionObserver from 'hook/useIntersectionObserver';
import First from 'components/Main/First';
import Second from 'components/Main/Second';
import Third from 'components/Main/Third';
import Fourth from 'components/Main/Fourth';

const Main = () => {
    const [isWhite, setIsWhite] = useState(false);
    const firstRef = useRef<any>(null);

    useEffect(() => {
        firstRef.current = document.getElementById('main_first') as HTMLElement;
    }, []);

    useIntersectionObserver({
        target: firstRef,
        threshold: 0.1,
        enabled: true,
        onIntersect: () => setIsWhite(true),
        offIntersect: () => setIsWhite(false),
    });

    return (
        <main className="bg-[#2e2e32]">
            <Header isWhite={isWhite} />
            <First />
            <Second />
            <Third />
            <Fourth />
        </main>
    );
}

export default Main;