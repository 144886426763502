import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Employed3 from 'assets/Employed/employed3.avif';
import Employed3Mo from 'assets/Employed/employed3_mo.avif';
import { GlobalContext } from 'App';
import Nav from '../Nav';

const First = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            id='first_div'
            className={`w-full bg-cover flex flex-col items-center justify-center py-33 ${isTablet ? 'gap-14' : 'h-[680px]'}`}
            style={{ backgroundImage: `url(${isTablet ? Employed3Mo : Employed3})` }}>
            <Container className='flex flex-col items-center'>
                <motion.button
                    className={`bg-[#0D0D93B2] pointer-events-none border border-white rounded-3xl py-2 px-6 mb-8 text-white font-bold ${isTablet ? 'text-base' : 'text-2xl'}`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    취업 아카데미
                </motion.button>

                <motion.p
                    className={`${isTablet ? 'text-32 text-center mb-1' : 'text-40'} text-white font-bold text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의

                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-32 text-center mb-1' : 'text-40'} text-white font-bold text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    의약품 전주기 교육
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-32 text-center mb-1' : 'text-40'} text-white font-bold text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    - 임상 & 비임상 -
                </motion.p>
            </Container>
            {
                isTablet &&
                <Nav />
            }
        </div>
    );
}

export default First;

