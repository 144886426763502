import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    const info = {
        career: [
            '충북대학교 약학대학 교수',
            '충북대학교 바이오미래융합기술경영대학원 교수',
            '대한약학회 산학협동부위원장',
            '단국대, 호서대, 청주대 겸임교수',
            '한독약품: 영업부 팀장, 사노피: 당뇨사업부 팀장',
            '2011 ~2012 진양제약: 마케팅전무',
            '2013 ~2015 한국 유나이티드제약: 마케팅 고문',
            '2016 대구한의대학교 제약공학과 교수',
            '2017 ~2018 서원대 제약공학과교수',
            '2018 ~2020 동아ST학술의약실 고문',
        ],
        writing: [
            '제약바이오산업 실전문제',
            '자신만만 제약영업 마케팅 실무',
            '자신만만 건강보험약가 정책',
            '자신만만 약처방',
            '제약바이오산업의 실무',
            '제약바이오 마케팅 개론',
            '의약품 약가정책의 현장',
            '의약품 인허가의 현장',
            '제약영업 마케팅의 현장',
            '제약바이오산업의 현장',
            '나는 오늘 취업한다',
            '자존감을 높이는 제약영업마케팅',
        ]
    }
    return (
        <Container
            className={`flex flex-col items-center justify-center  ${isTablet ? 'py-14 px-4 gap-18' : 'gap-21'}`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>

            <div className='flex flex-col gap-8'>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-32'} text-black font-bold text-left`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    대표자 이력
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-xl'} text-black font-medium text-left`}
                    transition={{ delay: 0.1 }}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의 임형식 대표는 약학 박사이며, 현재 충북대학교 약학대학 교수, 충북대학교 바이오미래융합기술경영대학원 교수,
                    대한약학회 산학협동부위원장, 단국대, 호서대, 청주대 겸임교수로 국내 제약바이오산업계의 최고 전문가입니다.
                </motion.p>
            </div>

            <div className={`grid ${isTablet ? 'gap-18' : 'grid-cols-2 '} w-full`}>
                <div className='w-[max-content]'>
                    <motion.p
                        className={`${isTablet ? 'text-2xl' : 'text-32'} text-black font-bold text-left`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        경력
                    </motion.p>
                    <hr className="text-[#B2B2B2] my-8" />
                    <motion.div
                        className='flex flex-col gap-[16px]'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        {
                            info.career.map((item, idx) => (
                                <li key={idx} className='text-left text-black font-medium'>{item}</li>
                            ))
                        }
                    </motion.div>
                </div>

                <div>
                    <motion.p
                        className={`${isTablet ? 'text-2xl' : 'text-32'} text-black font-bold text-left`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        저서
                    </motion.p>
                    <hr className="text-[#B2B2B2] my-8" />
                    <motion.div
                        className={`flex justify-between ${isTablet && 'flex-col gap-4'}`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <div className='flex flex-col gap-4'>
                            {
                                info.writing.splice(0, 7).map((item, idx) => (
                                    <li key={idx} className='text-left text-black font-medium'>{item}</li>
                                ))
                            }
                        </div>
                        <div className='flex flex-col gap-4'>
                            {
                                info.writing.map((item, idx) => (
                                    <li key={idx} className='text-left text-black font-medium'>{item}</li>
                                ))
                            }
                        </div>
                    </motion.div>
                </div>

            </div>
        </Container>
    );
}

export default Second;

