import React, { createContext, Fragment, useEffect, useState } from 'react';
import Main from 'screens/Main';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from 'components/Footer/Footer';
import useMediaQuery from './hook/useMediaQuery';
import Intro from 'screens/Intro';
import ScrollToTop from 'components/Common/ScrollToTop';
import Employed from 'screens/Employed';
import Startup from 'screens/Startup';
import News from 'screens/News';

interface IGlobalContext {
  isTablet: boolean;
  isMobile: boolean;
}

export const GlobalContext = createContext<IGlobalContext>({
  isTablet: false,
  isMobile: false,
});

const App = () => {
  const isTabletByQuery = useMediaQuery('(max-width: 768px)');
  const isMobileByQuery = useMediaQuery('(max-width: 576px)');
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsTablet(isTabletByQuery);
  }, [isTabletByQuery]);

  useEffect(() => {
    setIsMobile(isMobileByQuery);
  }, [isMobileByQuery]);

  return (
    <Fragment>
      <GlobalContext.Provider value={{ isTablet, isMobile }}>
        <div className="App">
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path='/' element={<Main />} />
              <Route path='/intro/*' element={<Intro />} />
              <Route path='/employed/*' element={<Employed />} />
              <Route path='/startup/*' element={<Startup />} />
              <Route path='/news/*' element={<News />} />
              <Route path='*'
                element={
                  <div>404 : 존재하지 않는 페이지입니다.</div>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
        <Footer />
      </GlobalContext.Provider>
    </Fragment>
  );
}

export default App;
