import React, { useContext } from 'react';
import Main3 from 'assets/Main/main3.avif';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="main_second" className={`${isTablet ? 'py-14' : 'py-30'} bg-white`}>
            <div className="container">
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-56'} text-black font-extrabold mb-10 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    제약바이오산업연구소에<br />
                    오신 것을 환영합니다
                </motion.p>
                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-2xl'} text-black font-medium mb-15 leading-8 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    제약바이오산업연구소는 제약･바이오 산업 전문 교육 플랫폼으로,{!isTablet && <br />}
                    업계 실무자, 현장 투입 예정자, 창업 준비생 등 다양한 고객의 니즈에 맞는 전문 교육을 제공합니다.{!isTablet && <br />}
                    산업의 이해가 풍부한 전문가로 구성된 교육자들과 현장실습을 통한 실전교육을 통해{!isTablet && <br />}
                    제약바이오 산업의 핵심인재로 성장할 수 있도록 최선을 다하겠습니다.
                </motion.p>

                <motion.div
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <img src={Main3} alt="Main3" />
                </motion.div>
            </div>
        </section>
    );
}

export default Second;

