import React, { useContext } from 'react';
import Main1 from 'assets/Main/main1.avif';
import Main2 from 'assets/Main/main2.avif';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from 'swiper/modules';
import './dots.css'

const First = () => {
    const { isTablet } = useContext(GlobalContext);
    const slides = [
        {
            title: `‘현장실습 연계’\n실전으로 배우는${isTablet ? '\n' : ''}제약･바이오 산업`,
            img: Main1,
            description: `제약바이오산업연구소는 제약･바이오 업체와의 제휴를 통해\n현장에 가서 직접 경험하고 배울 수 있는 실습 교육을 제공합니다.`,
            descriptionMo: `제약바이오산업연구소는 제약･바이오 업체와의\n제휴를 통해 현장에 가서 직접 경험하고 배울 수 있는\n실습 교육을 제공합니다.`
        },
        {
            title: `제약･바이오 특화${isTablet ? '\n' : ''}교육 플랫폼,\n‘제약바이오산업연구소’`,
            img: Main2,
            description: '제약바이오산업연구소는 제약･바이오 산업 전문 교육 플랫폼으로,\n의약품 전주기에 걸쳐 핵심역량강화를 위한 교육을 제공합니다.',
            descriptionMo: '제약바이오산업연구소는 제약･바이오 산업 전문 교육\n플랫폼으로, 의약품 전주기에 걸쳐 핵심역량강화를\n위한 교육을 제공합니다.'
        },
    ];

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <section id="main_first" className="h-screen">
            <Swiper
                autoplay={true}
                loop
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                pagination={pagination}
            >
                {
                    slides.map((item, idx) => (
                        <SwiperSlide className={'w-full h-screen bg-cover bg-bottom flex-col items-center justify-center gap-10'} key={idx} style={{
                            backgroundImage: `url(${item.img})`,
                            height: '100vh',
                            display: 'flex',
                        }}>
                            <motion.p
                                className={`${isTablet ? 'text-32' : 'text-56'} text-white font-bold whitespace-pre-line text-center`}
                                initial={START_ANIMATION_PROPERTY}
                                whileInView={END_ANIMATION_PROPERTY}
                                viewport={VIEWPORT_PROPERTY}>
                                {item.title}
                            </motion.p>
                            <motion.p
                                className={`${isTablet ? 'text-base' : 'text-2xl'}  text-white font-normal whitespace-pre-line text-center`}
                                transition={{ delay: 0.1 }}
                                initial={START_ANIMATION_PROPERTY}
                                whileInView={END_ANIMATION_PROPERTY}
                                viewport={VIEWPORT_PROPERTY}>
                                {isTablet ? item.descriptionMo : item.description}
                            </motion.p>

                            <ContactBtn />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </section>
    );
}

export default First;

