import React, { Fragment, useCallback, useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const Third = () => {
    interface Data {
        count: number,
        title: string,
        subTitle: string,
        contents: string
    }

    const { isTablet } = useContext(GlobalContext);

    const data1: Data[] = [
        {
            count: 1, title: '연구기초', subTitle: '의약품 개발 전주기의 이해', contents: '바이오 및 케미칼 의약품의 시장과 전반적인 의약품 개발 전주기의 이해'
        },
        {
            count: 2, title: '연구기초', subTitle: '연구기획과 연구기초\n(연구윤리, 통계, IP, 데이터관리) ', contents: '국내외 의약품 개발 환경 및 최근 이슈 / 규제기관에서 요구하는 연구윤리, 통계, IP 및 데이터 관리에 대한 이해'
        },
        {
            count: 3, title: '연구기초', subTitle: '기술사업화 전략', contents: '분석기술 이전을 활용한 효과적인 기술 사업화 전략 및 관련 정책에 대한 이해'
        },
        {
            count: 4, title: '연구기초', subTitle: 'QbD의 이해', contents: 'QbD의 기본 개념과 실제 산업 현장에서의 적용 / QbD 예시모델 개발과 의약품 품질에 미치는 영향 분석 및 시험방법의 개발 과정에 대한 이해'
        },
    ]

    const data2: Data[] = [
        {
            count: 5, title: '공정개발', subTitle: '원료합성 연구', contents: '원료물질 발굴 및 원료합성의 개발과 원료의약품 생산 과정에 대한 이해'
        },
        {
            count: 6, title: '공정개발', subTitle: '공정연구\n(배양, 정제공정)', contents: '바이오의약품의 대표공정인 배양공정과 정제공정 소개 및 바이오의약품의 원료의약품의 제조과정에 대한 이해'
        },
        {
            count: 7, title: '공정개발', subTitle: '제제연구(고형제, 주사제)', contents: '주사제 및 고형제의 개발과 제제 연구에 대한 이해'
        },
        {
            count: 8, title: '공정개발', subTitle: '제제연구(바이오의약품)', contents: '인슐린, 성장호르몬, 항체의약품 및 백신 의약품의 개발과 상용화 과정을 통한 바이오 의약품의 이해'
        },
    ]

    const data3: Data[] = [
        {
            count: 9, title: '분석 공정 개발', subTitle: '분연구석\n(시험법 개발 및 품질 분석)', contents: 'ICH 가이드라인에 따른 의약품 시험법(분석법) 개발 및 MV에 대한 이해 / 규제기관에서 요구하는 품질 분석 및 의약품 공정서와 품질 관련 규제에 대한 이해'
        },
    ]

    const data4: Data[] = [
        {
            count: 10, title: '기술 이전', subTitle: '제조 및 분석 기술 이전', contents: '의약품 제조와 분석기술이전의 이해, 및 시험법 이전 과정에서 필요한 문서와 법적 절차에 대한 이해'
        },
    ]

    const data5: Data[] = [
        {
            count: 11, title: '임상 및 허가', subTitle: 'IND와 임상실험', contents: 'IND 신청과 승인과정 및 생물학적동등성시험과 임상시험 적용을 위한 과정의 이해'
        },
        {
            count: 12, title: '임상 및 허가', subTitle: 'CMC 작성', contents: 'CMC의 정의와 의약품 연구개발 및 제조공정과 관련하여CMC의 필요성 및 관리 절차에 대한 이해'
        },
    ]

    const DataGrid = useCallback(({ data }: any) => (
        <motion.div
            className='grid grid-cols-[1fr_1fr_2fr_4.5fr] gap-x-1 gap-y-1.5 mb-[1rem]'
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={VIEWPORT_PROPERTY}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        {idx === 0 && (
                            <p className={`${gridInnerStyle(0)} border-b-[#D9E2F4] py-6 text-left font-bold bg-[#F7F8FF]`}
                                style={{ gridRow: setRowSpan(data, item.title) }}>
                                {item.title}
                            </p>
                        )}
                        <p className={`${gridInnerStyle(0)} border-b-[#E3EDF4] py-6 font-bold`}>{item.count}</p>
                        <p className={`${gridInnerStyle(1)} border-b-[#E3EDF4] py-6 px-8 text-left font-bold`}>{item.subTitle}</p>
                        <p className={`${gridInnerStyle(1)} border-b-[#E3EDF4] py-6 px-8 text-left font-semibold`}>{item.contents}</p>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])


    const DataGridMo = ({ data }: any) => (
        <motion.div
            className={`flex flex-col mb-[1rem]`}
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={{ once: true, amount: 0.1 }}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        {idx === 0 && (
                            <p className={`text-xl text-black  border-b-4 border-b-[#D9E2F4] p-4 text-left font-bold bg-[#DBE7F9] mb-4`}>
                                {item.title}
                            </p>
                        )}

                        <div className='flex w-full items-center justify-start bg-[#F1F6FA] gap-4'>
                            <p className={`text-base text-black border-b-0 py-2 pl-4 font-bold`}>{item.count}</p>
                            <p className={`text-base text-black border-b-0 py-2 text-left font-bold whitespace-pre-line`}>{item.subTitle}</p>
                        </div>

                        <p className={`text-black border-b-4 text-sm border-b-[#E3EDF4] py-2 px-4 text-left font-semibold mb-2`}>{item.contents}</p>
                    </Fragment>
                ))
            }
        </motion.div>
    );


    const gridInnerStyle = (num: number) => {
        return `flex ${num === 0 ? 'justify-center' : 'justify-start'} items-center border-b-4 whitespace-pre-line text-black text-xl`
    }

    const gridTitleStyle = () => {
        return `bg-[#DBE7F9] border-b-4 border-b-[#C8D0E5] text-xl p-1.5 text-black`
    }

    const setRowSpan = (data: Data[], title: string) => {
        const filterArr = data.filter(arr => arr.title === title)
        return `span ${filterArr.length}`
    }
    return (
        <Container className={`flex flex-col ${isTablet ? 'gap-6' : 'gap-15'}`}>
            <motion.p
                className={`${isTablet ? 'text-2xl leading-10' : 'text-48'} text-black font-extrabold text-left`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                의약품 전주기 연구개발 교육{isTablet && <br />}  커리큘럼
            </motion.p>

            <div>
                {
                    !isTablet &&
                    <motion.div
                        className='grid grid-cols-[1fr_1fr_2fr_4.5fr] gap-x-1 mb-[1rem]'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <p className={`${gridTitleStyle()} text-center`}>분야</p>
                        <p className={`${gridTitleStyle()} text-center`}>차시</p>
                        <p className={`${gridTitleStyle()} text-left pl-8`}>교과목명</p>
                        <p className={`${gridTitleStyle()} text-left pl-8`}>학습내용</p>
                    </motion.div>
                }

                {
                    isTablet ?
                        <>
                            <DataGridMo data={data1} />
                            <DataGridMo data={data2} />
                            <DataGridMo data={data3} />
                            <DataGridMo data={data4} />
                            <DataGridMo data={data5} />
                        </> :
                        <>
                            <DataGrid data={data1} />
                            <DataGrid data={data2} />
                            <DataGrid data={data3} />
                            <DataGrid data={data4} />
                            <DataGrid data={data5} />
                        </>
                }

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>

                <motion.div
                    className={isTablet ? 'py-18' : 'py-44'}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </div>

        </Container>
    );
}

export default Third;
