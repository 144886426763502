import React, { useContext } from 'react';
import First from './First';
import Second from './Second';
import Third from './Third';
import Nav from '../Nav';
import { GlobalContext } from 'App';

const Price = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="employed_price">
            <First />
            {
                !isTablet &&
                <Nav />
            }
            <Second />
            <Third />
        </section>
    );
}

export default Price;

