import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LogoW } from "assets/Common/logo_w.svg";
import { ReactComponent as Logo } from "assets/Common/logo.svg";
import { ReactComponent as Arrow } from "assets/Common/arrow_right.svg";
import { ReactComponent as MenuW } from "assets/Common/menu_w.svg";
import { ReactComponent as Menu } from "assets/Common/menu.svg";
import { ReactComponent as Close } from "assets/Common/close.svg";
import { ReactComponent as Icon } from "assets/Common/icon.svg";

import './style.css'
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

interface HeaderProps {
    isWhite?: boolean
}

const Header = ({ isWhite }: HeaderProps) => {
    const [isClick, setIsClick] = useState<boolean>(false);
    const [select, setSelect] = useState<number>(-1);
    const fullLocation = useLocation();
    const { isTablet } = useContext(GlobalContext);

    const [isMenu, setIsMenu] = useState<boolean>(false);
    const menu = [
        {
            title: '회사소개',
            titleEn: 'intro',
            subTitle: '‘제약바이오산업연구소’를 소개합니다.',
            menuList: [
                { url: '/intro/introduce', title: '회사 개요' },
                { url: '/intro/management', title: '경영 이념' },
                { url: '/intro/greetings', title: '대표자 인사말' },
                { url: '/intro/advisory', title: '자문위원단' },
            ]
        },
        {
            title: '취업 아카데미',
            titleEn: 'employed',
            subTitle: '제약바이오 산업군을 꿈꾸는 사람들을 위한 현장 실무에 필요한 모든 것들입니다.',
            menuList: [
                { url: '/employed/program', title: '의약품 전주기' },
                { url: '/employed/develop', title: '연구개발' },
                { url: '/employed/clinical', title: '임상 & 비임상' },
                { url: '/employed/license', title: '인허가' },
                { url: '/employed/price', title: '의약품 약가' },
                { url: '/employed/gmp', title: 'GMP' },
                { url: '/employed/slaes', title: 'Slaes & Marketing' },
            ]
        },
        {
            title: '창업 아카데미',
            titleEn: 'startup',
            subTitle: '제약바이오 산업의 창업을 꿈꾸는 모든 사람들을 위한 필수 교육입니다.',
            menuList: [
                { url: '/startup', title: '창업 아카데미' }
            ]
        },
        {
            title: '뉴스&정보',
            titleEn: 'news',
            subTitle: '제약바이오산업의 각종 뉴스와 정보를 공유하고 토론합니다.',
            menuList: [
                { url: '/news', title: 'NEWS' },
            ]
        },
    ]

    const enter = (idx: number) => {
        setIsClick(true)
        setSelect(idx)
    }

    const leave = () => {
        setIsMenu(false);
        setIsClick(false);
        setSelect(-1);
    }

    return (
        <div className={`w-full fixed z-50 ${isWhite ? 'backdrop-blur-xl' : 'bg-white'}`}
            onMouseLeave={leave}>
            <header className={`flex w-full 
            border-b-1 ${isWhite ? 'border-[#ffffff50]' : 'border-[#C0C0C0]'}`}>
                <nav className='container flex justify-between items-center'>
                    <div className={`max-w-5 ${isTablet ? 'py-3' : 'py-10'} `}>
                        <Link to={'/'}>
                            {
                                isWhite ?
                                    <LogoW className={`${isTablet && 'w-36.5'}`} /> :
                                    <Logo className={`${isTablet && 'w-36.5'}`} />
                            }
                        </Link>
                    </div>

                    {
                        isTablet ?
                            <>
                                {
                                    isWhite ?
                                        <MenuW onClick={() => { setIsMenu(true) }} /> :
                                        <Menu onClick={() => { setIsMenu(true) }} />
                                }
                            </> :
                            <ul className='flex gap-6'>
                                {
                                    menu.map((item, idx) => (
                                        <li key={idx}
                                            className='cursor-pointer'
                                            onClick={() => { enter(idx) }}
                                            onMouseEnter={() => { enter(idx) }}
                                        >
                                            <p
                                                className={`text-lg 
                                            ${fullLocation.pathname.includes(item.titleEn) && 'font-bold'}
                                            ${isWhite ? 'text-white' : 'text-black'}`}>{item.title}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                    }
                </nav>
            </header>

            {
                isClick &&
                <div className={`flex w-full justify-center pb-33`}>
                    <div className='container grid grid-cols-4 w-full'>
                        <motion.div
                            className='w-[315px] p-10 flex flex-col gap-[16px]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}>
                            <p className={`text-lg font-bold ${isWhite ? 'text-white' : 'text-black'} text-left`}>
                                {menu[select].title}
                            </p>
                            <p className={`text-xs ${isWhite ? 'text-white' : 'text-black'} text-left`}>
                                {menu[select].subTitle}
                            </p>
                        </motion.div>

                        <div className='col-span-3 grid grid-cols-3 w-full'>
                            {
                                menu[select].menuList?.map((item, idx) => (
                                    <Link to={item.url} key={idx}>
                                        <motion.div
                                            className={`w-[315px] justify-center px-6 
                                            ${(select === 0 || select === 1) ? 'py-10' : 'pt-10 pb-21 '} w-full 
                                            ${isWhite ? '' : 'headerLink'}`}
                                            onClick={leave}
                                            initial={START_ANIMATION_PROPERTY}
                                            whileInView={END_ANIMATION_PROPERTY}
                                            viewport={VIEWPORT_PROPERTY}
                                            transition={{ delay: 0.1 * idx }}
                                        >
                                            <div className='flex justify-between'>
                                                <p className={`text-lg ${isWhite ? 'text-white' : 'text-black'}`}>{item.title}</p>
                                                <Arrow fill='#B2B2B2' />
                                            </div>
                                            <hr className="text-[#B2B2B2] mt-2.5 border-t-2" />
                                        </motion.div>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }

            {
                isMenu &&
                <motion.div
                    className={`absolute bg-white w-full h-screen top-0 left-0 justify-center`}
                    initial={{
                        opacity: 0,
                        transform: 'translateX(100px)',
                    }}
                    whileInView={{
                        opacity: 1,
                        transform: 'translateX(0px)',
                    }}
                    viewport={VIEWPORT_PROPERTY}
                >
                    <div className='flex justify-between py-[0.8125rem] px-[1rem] border-b-1 border-b-[#C7C7C7]'>
                        <Icon />
                        <Close onClick={() => { setIsMenu(false) }} />
                    </div>
                    {
                        menu.map((item, idx) => (
                            <>
                                <motion.div
                                    key={idx}
                                    className={`justify-center w-full border-b-1 py-[0.8125rem] bg-white px-[1rem] border-b-[#EBEBEB]`}
                                    onClick={() => {
                                        setSelect(idx)
                                    }}
                                    initial={START_ANIMATION_PROPERTY}
                                    whileInView={END_ANIMATION_PROPERTY}
                                    viewport={VIEWPORT_PROPERTY}
                                    transition={{ delay: 0.1 * idx }}
                                >
                                    <div className='flex justify-between'>
                                        <p className={`text-lg ${select === idx ? 'text-[#0D0D93]' : 'text-black'} font-bold`}>{item.title}</p>
                                    </div>
                                </motion.div >
                                {
                                    select === idx &&
                                    menu[select].menuList?.map((item, idx) => (
                                        <Link to={item.url} key={idx}>
                                            <motion.div
                                                className={`justify-center w-full border-b-1 py-[0.8125rem] px-[1rem] border-b-[#EBEBEB] bg-[#F7F7FF]`}
                                                onClick={leave}
                                                initial={START_ANIMATION_PROPERTY}
                                                whileInView={END_ANIMATION_PROPERTY}
                                                viewport={VIEWPORT_PROPERTY}
                                                transition={{ delay: 0.1 * idx }}
                                            >
                                                <div className='flex justify-between'>
                                                    <p className={`text-lg text-black font-medium`}>{item.title}</p>
                                                </div>
                                            </motion.div>
                                        </Link>
                                    ))
                                }
                            </>

                        ))
                    }
                </motion.div>
            }
        </div >
    );
}

export default Header;