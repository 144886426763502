import React, { useContext } from 'react';
import Container from '../Common/Container';
import { ReactComponent as LogoW } from "assets/Common/logo_w.svg";
import { GlobalContext } from 'App';

const Footer = () => {
    const { isTablet } = useContext(GlobalContext);

    return (
        <footer className={`bg-[#212121] ${isTablet ? 'py-13' : 'py-18'}`}>
            <Container className={`flex flex-col ${isTablet ? 'gap-8' : 'gap-10'}`}>
                <div>
                    <LogoW className={`${isTablet && 'w-36.5'}`} />
                </div>

                <div className={`flex flex-col gap-3 text-[#FFFFFF7A] ${isTablet && 'text-xs'}`}>
                    {
                        isTablet ?
                            <>
                                <p>대표 : 임형식</p>
                                <a href="tel:+821092463133">전화 :  010-9246-3133</a>
                                <a href="mailto:ihs311@naver.com">이메일 : ihs311@naver.com</a>
                                <p><strong>제약바이오산업연구소 |</strong> 사업자 등록번호 : 837-88-03203</p>
                            </> :
                            <>
                                <p><a href="tel:+821092463133">전화 :  010-9246-3133</a> | <a href="mailto:ihs311@naver.com">이메일 : ihs311@naver.com</a></p>
                                <p><strong>제약바이오산업연구소 |</strong> 사업자 등록번호 : 837-88-03203 | 대표 : 임형식</p>
                            </>
                    }
                    <p><strong>본사 |</strong> 충청북도 청주시 서원구 충대로 1, S1-2동 201호 (충북대학교)</p>
                    <p><strong>연구소 |</strong>  서울특별시 송파구 올림픽로 35길 93, 102-2901</p>
                </div>

                <strong className={`text-[#FFFFFF7A] ${isTablet && 'text-xs'}`}>Copyright © 2024. CEO. All rights reserved.</strong>
            </Container>
        </footer>
    );
}

export default Footer;