import React, { useContext } from "react";
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from "App";

interface ContactBtnProps {
    delay?: number,
    className?: string
    bg?: string
}


const ContactBtn = ({ delay, className, bg }: ContactBtnProps) => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <a href="mailto:ihs311@naver.com" className='flex flex-col items-center'>
            <motion.button
                className={`${className ? `${className} ` : ''}${bg ? `${bg} ` : 'bg-[#ffffff30]'} border rounded-[3rem] py-2.5 px-6 ${isTablet ? 'text-base' : 'text-2xl'} text-white font-bold`}
                transition={{ delay: delay || 0 }}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}>
                교육문의하기
            </motion.button>
        </a>
    );
}

export default ContactBtn;
