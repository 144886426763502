import React, { useContext } from 'react';
import First from './First';
import { GlobalContext } from 'App';
import Bottom from './Bottom';
import BottomMo from './BottomMo';

const Management = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="intro_management">
            <First />

            {
                isTablet ?
                    <BottomMo /> :
                    <Bottom />
            }

        </section>
    );
}

export default Management;

