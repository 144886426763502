import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const First = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className={`py-25 flex flex-col ${isTablet ? 'items-center' : 'items-start'}`}>
            <motion.button
                className="bg-[#5656B3] border border-[#0D0D93] rounded-3xl py-1 px-4 mb-6 pointer-events-none text-xl text-white font-bold"
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                경영 이념
            </motion.button>

            <motion.p
                className={`${isTablet ? 'text-32 text-center' : 'text-40 text-left'} text-black font-extrabold`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                제약바이오산업연구소
            </motion.p>


            <motion.p
                className={`${isTablet ? 'text-2xl text-center' : 'text-40 text-left'} text-black font-extrabold`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                (Pharmaceutical{isTablet && <br />} & Bio Education Lab)
            </motion.p>
        </Container>
    );
}

export default First;

