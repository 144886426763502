import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Program1 from 'assets/Employed/Program/program1.avif';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container>
            <div className={`flex flex-col gap-6 justify-center py-30 items-center`}>
                <motion.p
                    className={`text-40 text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>

                <motion.p
                    className={`text-xl text-center leading-10 text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 제약바이오 산업의 이해를 바탕으로 고객사의 교육니즈와 문제를 이해하고 이를 해결할 수 있는 솔루션을 제공합니다.<br />
                    이를 위해 의약품 전주기의 전문영역에 대해 관련 분야의 전문가들과 함께 교육 프로그램을 제공합니다.
                </motion.p>

                <motion.div
                    className={`bg-[#F9F9F9] py-4.5 w-full`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    <p className={`text-22 text-black font-bold text-center`}>1. 핵심 단계 목적 이해</p>
                    <p className={`text-22 text-black font-bold text-center`}>2. 핵심 단계 주요 역량과 기준 학습</p>
                    <p className={`text-22 text-black font-bold text-center`}>3. Case study 중심의 문제 해결 능력 강화</p>
                    <p className={`text-22 text-[#0D0D93] font-bold text-center`}>4. 학습과 산업현장의 연계 : 학습내용의 실전훈련을 위한 현장 학습</p>
                </motion.div>

                {
                    isTablet ?
                        <div></div> :
                        <motion.div
                            className="py-4.5 w-full"
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <img src={Program1} alt="Program1" />
                        </motion.div>
                }
            </div>
        </Container>
    );
}

export default Second;

