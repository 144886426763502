import React, { useContext } from 'react';
import AdvisoryImg from 'assets/Intro/advisory.avif';
import AdvisoryImgMo from 'assets/Intro/advisory_mo.avif';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Advisory = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="intro_advisory" className={`${isTablet ? 'pt-14' : 'py-33'} bg-white`}>
            <Container className='flex flex-col items-center px-4'>
                <motion.button
                    className={`bg-[#0D0D93B2] pointer-events-none border-[#0D0D93] rounded-3xl py-2 px-6 mb-8 text-white font-bold ${isTablet ? 'text-base' : 'text-2xl'}`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    자문 위원단
                </motion.button>

                <motion.p
                    className={`${isTablet ? 'text-32 text-center leading-10' : 'text-40'} text-black font-bold  mb-10`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는{isTablet && <br />} 산업 최고의 자문위원{isTablet && <br />}단과 함께합니다.
                </motion.p>

                <hr className="text-black my-8 border-t-4 w-8 mx-auto" />

                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-xl'} text-black font-medium mb-15 leading-8 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 제약바이오 산업에서 오랜 경험과 전문성을 갖춘 최고의 전문가들로 구성된 자문위원단과 함께합니다.{isTablet && <br />}
                    연구개발, 임상시험, 규제 대응, 마케팅 등 다양한 분야에서 풍부한 현장 경험을 쌓아온 업계 리더들로,{isTablet && <br />}
                    최신 산업 동향과 규제 변화에 대한 깊은 이해를 바탕으로, 맞춤형 컨설팅과 교육 프로그램 개발에 참여하며, 실질적이고 실행 가능한 해결책을 제시합니다.{isTablet && <br />}
                    여러분의 성공적인 비즈니스와 전문 지식 함양을 위한 든든한 파트너가 되어드릴 것입니다.
                </motion.p>

                {
                    !isTablet &&
                    <motion.div
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <img src={AdvisoryImg} alt="AdvisoryImg" />
                    </motion.div>
                }
            </Container>

            {
                isTablet &&
                <motion.div
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    <img src={AdvisoryImgMo} alt="AdvisoryImg" />
                </motion.div>
            }
        </section>
    );
}

export default Advisory;

