import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Startup1 from 'assets/Startup/startup1.avif';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const StartupInner = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="startup" className={`${isTablet ? 'pt-14' : 'pt-33'} bg-white`}>
            <Container className={`flex flex-col items-center ${isTablet ? 'gap-8' : 'gap-30'}`}>
                <div className='flex flex-col items-center'>
                    <motion.button
                        className={`bg-[#0D0D93B2] pointer-events-none border-[#0D0D93] rounded-3xl py-2 px-6 mb-8 text-white font-bold ${isTablet ? 'text-base' : 'text-2xl'}`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        창업아카데미
                    </motion.button>

                    <motion.p
                        className={`${isTablet ? 'text-32' : 'text-40'} text-black font-medium`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        제약바이오산업연구소
                    </motion.p>

                    <motion.p
                        className={`${isTablet ? 'text-32' : 'text-40'} text-black font-bold`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        - 창업 아카데미 -
                    </motion.p>
                </div>

                <hr className="text-black border-t-4 w-8 mx-auto" />

                <div className={`flex ${isTablet ? 'flex-col-reverse' : ''}  justify-between items-center`}>
                    <motion.div
                        className='flex-1'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <img src={Startup1} alt="Startup1" />
                    </motion.div>
                    <motion.p
                        className={`${isTablet ? 'text-base leading-7 text-center mb-15' : 'text-xl pl-15 flex-1 leading-10 text-left'} text-black font-medium `}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        제약바이오산업연구소의 창업 아카데미는 창업을 꿈꾸는 예비 창업자들에게{!isTablet && <br />}
                        맞춤형 교육과 전문 컨설팅을 제공하는프로그램입니다.<br />
                        창업아카데미에서는 제약바이오 산업의 시장 분석, 사업 계획 수립, 자금 조달{!isTablet && <br />}
                        전략, 규제 대응 및 기술 상용화 과정 등 창업에 필요한 핵심 지식을 체계적으로{!isTablet && <br />}
                        다루며, 현업 전문가의 멘토링을 통해 실질적인 창업 노하우를 전수합니다.
                    </motion.p>
                </div>

                <motion.p
                    className={`${isTablet ? 'text-base leading-7' : 'text-xl leading-10'} text-black font-medium text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    초기 단계부터 성장 단계까지 창업 과정에서 직면할 수 있는 다양한 문제를 해결할 수 있도록 지원하며,<br />
                    네트워킹 기회를 제공하여 투자자 및 업계 전문가와의 연결을 제공합니다.<br />
                    제약바이오산업연구소의  창업 아카데미를 통해 제약바이오 분야에서 혁신적인 아이디어를 현실로 만들고,<br />
                    성공적인 창업을 이끌어가는 데 필요한 든든한 파트너가 되어드리겠습니다.
                </motion.p>

                <motion.div
                    className={`${isTablet ? 'mb-18' : 'mb-30'}`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </Container>
        </section>
    );
}

export default StartupInner;

