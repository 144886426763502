import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Intro2 from 'assets/Intro/intro2.avif';
import { GlobalContext } from 'App';

const Third = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            className={`flex ${isTablet ? 'flex-col px-4 gap-8 mb-18' : 'gap-5'} items-center justify-between`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>
            <motion.div
                className="flex-1"
                transition={{ delay: 0.1 }}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                <img src={Intro2} alt="Intro2" />
            </motion.div>
            <div className='flex-1 flex flex-col gap-8'>
                <motion.p
                    className={`${isTablet ? 'text-2xl text-center' : 'text-32 text-left pl-13'} text-black font-bold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    혁신적인 교육 프로그램
                </motion.p>
                <motion.p
                    className={`${isTablet ? 'text-base text-center' : 'text-2xl text-left pl-13 leading-10'} text-black font-medium  leading-7`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소에서는 생명과학 교육에 대한 새로운 접근 방식을 도입하여{!isTablet && <br />}
                    이론과 실무를 동시에 익힐 수 있도록 지원하며,  최신 연구 동향과 기술을 반영한{!isTablet && <br />}
                    커리큘럼을 통해 실질적이고 실용적인 지식을 습득할 수 있도록 노력합니다.
                </motion.p>
            </div>
        </div>
    );
}

export default Third;

