import React, { Fragment, useCallback, useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const Third = () => {
    interface Data {
        title: string,
        contents: string[]
    }

    const { isTablet } = useContext(GlobalContext);

    const data1: Data[] = [
        {
            title: '제약산업의 이해',
            contents: ['제약산업의 개요', '글로벌 제약 산업', '국내 제약산업']
        },
        {
            title: '의약품 개론',
            contents: ['의약품 정의와 의약품의 특징', '의약품 역사 및 분류', '의약품 부작용', '의약품 개발과정의 이해', '약사 관련 법령체계', '의약품 허가 및 관리제도', 'ICH 개요 및 가이드라인', 'CTD 개요']
        },
        {
            title: '의약품 허가',
            contents: ['의약품과 품질', '공정서 / 대한민국약전', '의약품의 기준 및 시험방법', '시험법 밸리데이션']
        },
        {
            title: 'GMP 실습',
            contents: ['의약품과 GMP', '적격성 평가 및 밸리데이션', '제조관리 : 고형제 의약품의 이해', '제조관리 : 원료의약품의 이해', 'GMP 시스템의 이해 : FDA실사', '중심 6개 품질 시스템', '품질관리 및 안정성 시험', '제조관리 : 주사제 의약품의 이해', '시설 및 환경의 관리']
        }
    ]

    const data2: Data[] = [
        {
            title: '바이오 / 제약산업의 이해',
            contents: ['바이오 / 제약산업 개요', '글로벌 / 국내 바이오 / 제약산업', '바이오 / 제약산업', '의약품 개발 과정 소개']
        },
        {
            title: '의약품 허가',
            contents: ['의약품 허가 및 관리제도', 'ICH 개요 및 가이드라인', 'CTD 개요']
        },
        {
            title: '의약품 기준 및 시험 방법',
            contents: ['의약품과 품질', '공정서(해외중심)', '의약품의 기준 및 시험방법', '시험법 밸리데이션']
        },
        {
            title: 'GMP 실습',
            contents: ['의약품과 GMP', '적격성 평가 및 밸리데이션', '제조관리: 고형제 의약품의 이해', '제조관리: 원료의약품의 이해', 'GMP 시스템의 이해: FDA실사', '중심 6개 품질 시스템', '품질관리 및 안정성 시험', '제조관리: 주사제 의약품의 이해', '시설 및 환경의 관리']
        },
        {
            title: '임상시험의 이해',
            contents: ['신약개발 과정 및 바이오 / 제약 메디컬 부서의 역할', '임상시험의 이해(과정, 바이오 / 제약사와 CRO의 조직 구성 및 역할)']
        },
        {
            title: '임상시험의 준비',
            contents: ['임상시험 대상자 동의서의 개발 및 취득절차', '임상시험 계획서 및 증례 기록서의 이해', '임상시험용 의약품 등의 이해']
        },
        {
            title: 'CRA의 역할',
            contents: ['임상시험 준비과정에서의 CRA의 역할(기관 선정, HA / IRB 승인, 계약, 개시 모임)', '임상시험 진행과정에서의 CRA의 역할(모니터링 업무, 근거문서, 안전성 모니터링)']
        },
        {
            title: '임상시험 종료 및 전망',
            contents: ['임상시험 종료 절차', '점검(audit) 및 실태조사(inspection)의 이해', '의뢰자와 CRO의 콜라보레이션', 'CRA 준비하기']
        }
    ]



    const DataGrid = useCallback(({ data }: any) => (
        <motion.div
            className={`grid ${isTablet ? '' : 'grid-cols-[1fr_2.3fr]'}  gap-x-1 gap-y-1.5 mb-[1rem]`}
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={{ once: true, amount: 0.1 }}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        <p className={`${gridInnerStyle(0)}} border-b-[#D9E2F4] text-left text-xl font-bold 
                        ${isTablet ?
                                'bg-[#F1F6FA] px-4 text-base py-2' :
                                'bg-[#F7F8FF] border-b-4 text-xl justify-center py-6'
                            }`}>
                            {item.title}
                        </p>
                        <div className={`${gridInnerStyle(1)} border-b-[#E3EDF4] border-b-4 flex-col 
                        ${isTablet ?
                                'px-4 py-2 text-sm' :
                                'px-8 py-6'
                            }`}>

                            {
                                item.contents.map((items, idxs) => (
                                    <motion.p
                                        key={idxs}
                                        className={`text-left font-semibold`}
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}>
                                        · {items}
                                    </motion.p>
                                ))
                            }
                        </div>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])

    const gridInnerStyle = (num: number) => {
        return `flex ${num === 0 ? 'items-center' : 'items-start'}  whitespace-pre-line text-black `
    }

    const gridTitleStyle = () => {
        return `bg-[#DBE7F9] border-b-4 border-b-[#C8D0E5] text-xl p-1.5 text-black font-semibold`
    }

    return (
        <Container className={`flex flex-col ${isTablet ? 'gap-6' : 'gap-15'}`}>
            <motion.p
                className={`${isTablet ? 'text-2xl leading-10' : 'text-48'} text-black font-extrabold text-left`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                GMP Basic 과정{isTablet && <br />} 커리큘럼
            </motion.p>

            <div>
                {
                    isTablet ?
                        <motion.div
                            className='mb-[1rem]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <p className={`${gridTitleStyle()} text-left ${isTablet ? 'pl-4 py-4' : 'pl-8'}`}>교과목명 / 학습내용</p>
                        </motion.div> :
                        <motion.div
                            className='grid grid-cols-[1fr_2.3fr] gap-x-1 mb-[1rem]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <p className={`${gridTitleStyle()} text-center`}>교과목명</p>
                            <p className={`${gridTitleStyle()} text-left pl-8`}>학습내용</p>
                        </motion.div>
                }


                <DataGrid data={data1} />

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>
            </div>

            <motion.p
                className={`${isTablet ? 'text-2xl leading-10 text-left' : 'text-48  text-right'} text-black font-extrabold`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                GMP Ex 과정{isTablet && <br />} 커리큘럼
            </motion.p>

            <div>
                {
                    isTablet ?
                        <motion.div
                            className='mb-[1rem]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <p className={`${gridTitleStyle()} text-left ${isTablet ? 'pl-4 py-4' : 'pl-8'}`}>교과목명 / 학습내용</p>
                        </motion.div> :
                        <motion.div
                            className='grid grid-cols-[1fr_2.3fr] gap-x-1 mb-[1rem]'
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <p className={`${gridTitleStyle()} text-center`}>교과목명</p>
                            <p className={`${gridTitleStyle()} text-left pl-8`}>학습내용</p>
                        </motion.div>
                }

                <DataGrid data={data2} />

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>

                <motion.div
                    className={isTablet ? 'py-18' : 'py-44'}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </div>

        </Container>
    );
}

export default Third;
