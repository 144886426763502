import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className='flex justify-center'>
            <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base text-left leading-7' : 'text-xl text-center leading-10'} text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의 의약품 Sales & Marketing 교육은 제약바이오 산업의 영업 및 마케팅 전략을 이해하고 실무에 적용할 수 있도록 설계된 전문 교육 과정입니다.<br />
                    의약품의 성공적인 시장 진입을 위한 마케팅 기초부터 최신 디지털 마케팅 전략, 영업 기술, 그리고 시장 분석 방법에 이르기까지 다양한 내용 교육합니다.<br />
                    제약 산업의 규제 환경을 고려한 마케팅 접근법과 영업 현장에서의 커뮤니케이션 스킬 등 현장 중심의 교육을 제공하여 제약 마케팅의 전문성을 높입니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

