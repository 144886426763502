import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import useIntersectionObserver from 'hook/useIntersectionObserver';
import Program from 'components/Employed/Program/Program';
import Develop from 'components/Employed/Develop/Develop';
import Clinical from 'components/Employed/Clinical/Clinical';
import License from 'components/Employed/License/License';
import Price from 'components/Employed/Price/Price';
import Gmp from 'components/Employed/Gmp/Gmp';
import Sales from 'components/Employed/Sales/Sales';

const Employed = () => {
    const fullLocation = useLocation();
    const location = fullLocation.pathname.split('/').pop();
    const [isWhite, setIsWhite] = useState(false);
    const firstRef = useRef<any>(null);

    useEffect(() => {
        setFirstRef();
    }, [location])

    const setFirstRef = useCallback(() => {
        firstRef.current = document.getElementById('first_div') as HTMLElement;
    }, [location]);

    useIntersectionObserver({
        target: firstRef,
        threshold: 0.1,
        enabled: true,
        onIntersect: () => setIsWhite(true),
        offIntersect: () => setIsWhite(false),
    });

    return (
        <main className="bg-white">
            <Header isWhite={isWhite} />
            {
                location === 'program' ?
                    <Program /> :
                    location === 'develop' ?
                        <Develop /> :
                        location === 'clinical' ?
                            <Clinical /> :
                            location === 'license' ?
                                <License /> :
                                location === 'price' ?
                                    <Price /> :
                                    location === 'gmp' ?
                                        <Gmp /> :
                                        <Sales />
            }
        </main>
    );
}

export default Employed;