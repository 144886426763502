import React, { useContext, useEffect, useState } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import News from 'assets/News/new.avif'
import NewsMo from 'assets/News/new_mo.avif'
import { ReactComponent as Left } from "assets/News/left.svg";
import { ReactComponent as Right } from "assets/News/right.svg";
import { GlobalContext } from 'App';

const NewsInner = () => {
    const [currentIdx, setCurrentIdx] = useState<number>(1);
    const { isTablet } = useContext(GlobalContext);
    const [pageNews, setPageNews] = useState<any[]>([]);

    const news: any[] = [

    ]

    const totalPages = Math.ceil(news.length / (isTablet ? 4 : 8)) || 1;

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setPageNews(paginateNews(news, currentIdx))
    }, [currentIdx, isTablet])

    const paginateNews = (news: any, currentIdx: number) => {
        const startIdx = (currentIdx - 1) * (isTablet ? 4 : 8);
        const endIdx = startIdx + (isTablet ? 4 : 8);
        return news.slice(startIdx, endIdx);
    }

    const goPrev = () => {
        if (currentIdx !== 1) {
            setCurrentIdx(currentIdx - 1)
        }
    }

    const goNext = () => {
        if (currentIdx !== totalPages) {
            setCurrentIdx(currentIdx + 1)
        }
    }
    return (
        <section id="news" className={`${isTablet ? 'pt-14 pb-18' : 'py-33'} bg-white`}>
            <Container className={`flex flex-col ${isTablet ? 'gap-8' : 'gap-30'}`}>
                <div className='flex flex-col items-center'>
                    <motion.button
                        className={`bg-[#0D0D93B2] pointer-events-none border-[#0D0D93] rounded-3xl py-2 px-6 mb-8 text-white font-bold ${isTablet ? 'text-base' : 'text-2xl'}`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        뉴스&정보
                    </motion.button>

                    <motion.p
                        className={`${isTablet ? 'text-32' : 'text-40'} text-black font-bold`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        제약바이오산업의{isTablet && <br />} 주요 정보를 한눈에
                    </motion.p>
                </div>

                <div className='flex flex-col gap-6'>
                    <motion.p
                        className={`${isTablet ? 'text-sm' : 'text-xl'} text-black font-medium text-left`}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        전체 <span className='font-bold text-[#0D0D93]'>{news.length}</span>
                    </motion.p>

                    <motion.div
                        className={`grid ${isTablet ? 'gap-4' : 'grid-cols-4 grid-rows-2 gap-10'} `}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        {
                            pageNews.length > 0 && pageNews.map((item, idx: number) => (
                                <div key={idx}>
                                    <motion.div
                                        className='mb-[1rem]'
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}>
                                        <img src={isTablet ? item.imgMo : item.img} alt="news" />
                                    </motion.div>
                                    <motion.p
                                        className={`${isTablet ? 'text-base mb-2' : 'text-2xl mb-[1rem]'} text-black font-extrabold text-left `}
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}
                                    >
                                        {item.title}
                                    </motion.p>

                                    <motion.p
                                        className={`${isTablet ? 'text-xs' : 'text-xl'} text-[#00000080] font-medium text-left`}
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}
                                    >
                                        {item.createAt}
                                    </motion.p>
                                </div>
                            ))
                        }
                    </motion.div>
                </div>

                {
                    news.length === 0 &&
                    <div className='h-[30vh]'></div>
                }
                <motion.div
                    className='flex gap-3 items-center justify-center'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <Left className='cursor-pointer' onClick={goPrev} />
                    <div className='flex gap-2 cursor-pointer'>
                        {Array(totalPages).fill(null).map((_, pageIndex) => (
                            <p
                                key={pageIndex}
                                onClick={() => setCurrentIdx(pageIndex + 1)}
                                className={`w-8 h-8 mr-1 flex items-center justify-center text-sm
                                ${pageIndex + 1 === currentIdx ? 'bg-[#0D0D93] text-white' : 'bg-gray-200'} rounded`}
                            >
                                {pageIndex + 1}
                            </p>
                        ))}
                    </div>
                    <Right className='cursor-pointer' onClick={goNext} />
                </motion.div>
            </Container>
        </section>
    );
}

export default NewsInner;

