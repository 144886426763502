import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Greetings1 from 'assets/Intro/greetings1.avif';
import { ReactComponent as Sign } from "assets/Intro/sign.svg";
import { GlobalContext } from 'App';

const First = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            className={`w-full bg-cover bg-bottom flex flex-col items-center justify-center ${isTablet ? 'py-14 px-4' : 'py-33'}`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)', backgroundImage: `url(${Greetings1})` }}>
            <Container className='flex flex-col items-center'>
                <motion.button
                    className={`bg-[#0D0D93B2] pointer-events-none border-[#0D0D93] rounded-3xl py-2 px-6 mb-8 text-white font-bold ${isTablet ? 'text-base' : 'text-2xl'}`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    대표자 인사말
                </motion.button>

                <motion.p
                    className={`${isTablet ? 'text-2xl text-center leading-10' : 'text-40'} text-black font-bold mb-10 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    안녕하세요,{isTablet && <br />} 제약바이오산업연구소{isTablet && <br />} 임형식 대표입니다.<br />
                    제약바이오산업연구소를{isTablet && <br />} 방문해주셔서 진심으로{isTablet && <br />} 감사드립니다.
                </motion.p>

                <hr className="text-black my-8 border-t-4 w-8 mx-auto" />

                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-xl'} text-black font-medium mb-15 leading-9 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 최고의 교육과 연구 환경을 제공하여{!isTablet && <br />}
                    생명과학의 미래를 이끌어갈 차세대 인재를 양성하고, 학생들이 창의적이고 실용적인 지식을 습득할 수 있도록 지원합니다.<br />{isTablet && <br />}
                    제약바이오산업연구소는 이론과 현장실습을 결합한 교육 프로그램을 통해 학생들이 실제 연구 현장에서 필요한 역량을 키울 수 있도록 돕고 있으며,{!isTablet && <br />}
                    이를 통해 실무적인 경험을 쌓고, 다양한 관점에서 문제를 해결할 수 있는 능력을 키울 수 있도록 최고의 파트너가 되어드리겠습니다.<br />{isTablet && <br />}
                    함께 더 나은 미래를 만들어 가는 여정에{isTablet && <br />} 많은 관심과 성원을 부탁드립니다.<br />
                    감사합니다.
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-xl'} text-black font-bold mb-15`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소 대표
                </motion.p>

                <motion.div
                    className='flex justify-center'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <Sign />
                </motion.div>
            </Container>
        </div>
    );
}

export default First;

