import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Intro5 from 'assets/Intro/intro5.avif';
import Intro5Mo from 'assets/Intro/intro5_mo.avif';
import { GlobalContext } from 'App';
import ContactBtn from 'components/Common/ContactBtn';

const Sixth = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div className={`flex flex-col items-center justify-center ${isTablet && 'mt-18'}`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>
            <motion.div
                className={`w-full ${isTablet ? 'py-18' : 'py-60'} bg-cover flex flex-col items-center justify-center gap-8`}
                style={{ backgroundImage: `url(${isTablet ? Intro5Mo : Intro5})` }}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                {
                    isTablet ?
                        <div>
                            <motion.p
                                className="text-2xl text-white font-bold"
                                initial={START_ANIMATION_PROPERTY}
                                whileInView={END_ANIMATION_PROPERTY}
                                viewport={VIEWPORT_PROPERTY}
                            >
                                제약바이오산업연구소
                            </motion.p>
                            <motion.p
                                className="text-base text-white font-bold"
                                initial={START_ANIMATION_PROPERTY}
                                whileInView={END_ANIMATION_PROPERTY}
                                viewport={VIEWPORT_PROPERTY}
                            >
                                - 생명과학 교육의 혁신을 이끌다
                            </motion.p>
                        </div> :
                        <motion.p
                            className="text-32 text-white font-bold"
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            제약바이오산업연구소 - 생명과학 교육의 혁신을 이끌다
                        </motion.p>
                }

                <ContactBtn />
            </motion.div>
        </div>
    );
}

export default Sixth;

