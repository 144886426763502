import React, { useContext } from 'react';
import Main4 from 'assets/Main/main4.avif';
import Main4Mo from 'assets/Main/main4_mo.avif';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Third = () => {
    const { isTablet } = useContext(GlobalContext);

    return (
        <section id="main_third" className={`${isTablet ? 'py-14' : 'py-30'} bg-white`}>
            <div className="container">
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-56'} text-black font-extrabold mb-10 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    제약바이오산업연구소의 교육
                </motion.p>
                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-2xl'} text-black font-medium mb-15 leading-8 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    제약바이오산업연구소는 의약품 전주기, 임상&비임상, 인허가, GMP, 약가, 판매 및 마케팅 등{!isTablet && <br />}
                    산업 전반에 필요한 <strong>전문 이론 지식</strong>과 <strong>현장실습을 통한 실전 연습</strong>을 통해{!isTablet && <br />}
                    제약바이오산업 인재로의 성장을 도모합니다.
                </motion.p>
                <motion.div
                    className='px-[1rem]'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <img src={isTablet ? Main4Mo : Main4} alt="Main4" />
                </motion.div>
            </div>
        </section>
    );
}

export default Third;

