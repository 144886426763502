import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Greetings2 from 'assets/Intro/greetings2.avif';
import Greetings2Mo from 'assets/Intro/greetings2_mo.avif';
import { GlobalContext } from 'App';

const Third = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            className={`flex justify-center bg-light-gray ${isTablet && 'py-18 px-3'}`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>
            <Container className='flex flex-col items-center justify-center gap-15'>
                <motion.p
                    className={`${isTablet ? 'text-2xl text-center' : 'text-32 text-left pr-13'} text-black font-bold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    <a href="https://store.kyobobook.co.kr/person/detail/1114173801" className='underline'>대표자 저서 보러가기</a>
                </motion.p>

                {
                    isTablet ?
                        <img src={Greetings2Mo} alt="Greetings2" /> :
                        <motion.div
                            className="flex items-center"
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}
                        >
                            <img src={isTablet ? Greetings2Mo : Greetings2} alt="Greetings2" />
                        </motion.div>
                }
            </Container>
        </div>
    );
}

export default Third;

