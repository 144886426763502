import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Intro3 from 'assets/Intro/intro3.avif';
import { GlobalContext } from 'App';

const Fourth = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            className={`flex ${isTablet ? 'flex-col-reverse px-4 gap-8 mb-18' : 'gap-5'} items-center justify-between`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>
            <div className='flex-1 flex flex-col gap-8'>
                <motion.p
                    className={`${isTablet ? 'text-2xl text-center' : 'text-32 text-right pr-13'} text-black font-bold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    현장 중심의 학습
                </motion.p>
                <motion.p
                    className={`${isTablet ? 'text-base text-center' : 'text-2xl text-right pr-13 leading-10'} text-black font-medium  leading-7`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 실제 연구 현장에서의 학습 기회를 제공합니다.{!isTablet && <br />}
                    연구실과 실험실에서 직접 실험하고, 현장 전문가들과 협력하여 실제 문제를{!isTablet && <br />}
                    해결하는 경험을 통해 단순히 교과서적인 지식이 아닌{!isTablet && <br />}
                    실질적인 연구 역량을 키울 수 있습니다.
                </motion.p>
            </div>
            <motion.div
                className="flex-1"
                transition={{ delay: 0.1 }}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                <img src={Intro3} alt="Intro3" />
            </motion.div>
        </div>
    );
}

export default Fourth;

