import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className='flex justify-center'>
            <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base text-left leading-7' : 'text-xl text-center leading-10'} text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의 연구개발 교육은 제약 및 바이오 산업에서 신약 및 바이오의약품 개발을 위한 필수 지식과 기술을 제공하는 과정입니다.<br />
                    제약바이오 산업의 기본 개념부터 신약 개발의 각 단계, 규제 및 품질관리, 그리고 상업화 전략까지 폭넓은 내용을 다룹니다.<br />
                    특히, 연구개발 과정에서 필요한 규제 과학 및 GMP, QA/QC와 같은 필수적인 실무 지식을 습득할 수 있습니다.<br />
                    제약바이오 분야에서의 연구개발 과정을 이해하고, 실제 업무에 적용할 수 있는 실질적인 역량을 갖출 수 있도록 함께하겠습니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

