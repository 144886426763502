import React, { Fragment, useCallback, useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const Third = () => {
    interface Data {
        title: string,
    }

    const { isTablet } = useContext(GlobalContext);

    const data1: Data[] = [
        { title: '제약 의료시장의 이해 I - 의약품 개발과정을 통한 제약산업의 이해' },
        { title: '제약 의료시장의 이해 II - 제약회사 구조 및 조직의 이해 / 제약 메디컬 부서의 이해' },
        { title: '제약 회사 취업하기 - 취업에 필요한 취업 특강 및 자소서 작성법' },
        { title: '약리학의 이해 - 의약품의 종류와 작용기전 이해' },
        { title: '의약품 마케팅의 이해 I - Treatment unmet needs와 의약품 마케팅의 이해' },
        { title: '제약 의료시장의 이해 II - 의약품 STP 전략의 이해' },
        { title: '의약품 영업 전략의 이해 - Sales force effectiveness' },
        { title: '의료 전문가와의 커뮤니케이션 전략 I - 방문 시작에서 효과적인 화제 사용하기' },
        { title: '의료 전문가와의 커뮤니케이션 전략 II - 설득적인 질문 기법과 이점 디테일링' },
        { title: '의료 전문가와의 커뮤니케이션 전략 III - 반대 다루기와 실행 이끌어내기' },
        { title: '의료 전문가와의 커뮤니케이션 전략 IV - 의료인 성향에 따른 접근법의 이해' }
    ]

    const DataGrid = useCallback(({ data }: any) => (
        <motion.div
            className='mb-[1rem]'
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={{ once: true, amount: 0.1 }}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        <motion.p
                            className={`${gridInnerStyle()}} border-b-[#D9E2F4] text-left font-semibold 
                            ${isTablet ?
                                    'bg-[#F1F6FA] pl-4 py-2 mb-2 text-base' :
                                    'pl-8 py-6 text-xl'}`}
                            initial={START_ANIMATION_PROPERTY}
                            whileInView={END_ANIMATION_PROPERTY}
                            viewport={VIEWPORT_PROPERTY}>
                            · {item.title}
                        </motion.p>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])

    const gridInnerStyle = () => {
        return `border-b-4 whitespace-pre-line text-black text-xl`
    }

    const gridTitleStyle = () => {
        return `bg-[#DBE7F9] border-b-4 border-b-[#C8D0E5] text-xl p-1.5 text-black font-semibold`
    }

    return (
        <Container className={`flex flex-col ${isTablet ? 'gap-6' : 'gap-15'}`}>
            <motion.p
                className={`${isTablet ? 'text-2xl leading-10' : 'text-48'} text-black font-extrabold text-left`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                의약품 Slaes & Marketing{isTablet && <br />} 커리큘럼
            </motion.p>

            <div>
                <motion.div
                    className='mb-[1rem]'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    <p className={`${gridTitleStyle()} text-left ${isTablet ? 'pl-4 py-4' : 'pl-8'}`}>교과목명</p>
                </motion.div>

                <DataGrid data={data1} />

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>

                <motion.div
                    className={isTablet ? 'py-18' : 'py-44'}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </div>

        </Container>
    );
}

export default Third;
