import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className='flex justify-center'>
            <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>


                <motion.p
                    className={`${isTablet ? 'text-base text-left leading-7' : 'text-xl text-center leading-10'} text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의 의약품 약가 교육은 제약바이오 산업에서 필수적인 약가 결정과 시장 접근 전략을 학습할 수 있는 전문 과정입니다.<br />
                    의약품 가격 책정의 기본 원리와 국내외 약가 규제 체계를 다루며, 건강보험 급여 등재, 약가 협상, 가치 기반 가격 결정 등의 핵심 주제의 커리큘럼으로 구성되었으며,<br />
                    실제 사례 분석과 최신 트렌드를 통해 의약품 시장 접근성을 높이기 위한 실질적인 전략을 제공합니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

