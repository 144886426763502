import React, { Fragment } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { ReactComponent as Arrow } from "assets/Intro/arrow.svg";
import ContactBtn from 'components/Common/ContactBtn';
import Manage1 from 'assets/Intro/manage1.avif';
import Manage2 from 'assets/Intro/manage2.avif';
import Manage3 from 'assets/Intro/manage3.avif';
import Manage4 from 'assets/Intro/manage4.avif';
import Manage5 from 'assets/Intro/manage5.avif';

const Bottom = () => {
    const data = [
        {
            title: '1. 혁신 & 창의성 (Innovation & Creativity)',
            description: '우리는 혁신과 창의성을 바탕으로 한 교육을 지향합니다. 끊임없는 연구와 학습을 통해 최신 바이오 기술과 교육 방법을 개발하고, 이를 학생들에게 제공하여 그들의 잠재력을 최대한 발휘할 수 있도록 합니다.',
            img: Manage1,
        },
        {
            title: '2. 학생 중심의 교육\n(Student - Centered Education) ',
            description: '학생의 성장과 발전이 우리의 최우선 과제입니다. 우리는 학생들의 개별적인 필요와 목표를 존중하며, 맞춤형 교육 프로그램을 제공하여 각자의 잠재력을 최대한으로 끌어올립니다.',
            img: Manage2,
        },
        {
            title: '3. 실무 중심의 학습\n(Practice - Oriented Learning) ',
            description: '이론과 실무를 결합한 교육을 통해 학생들이 실제 연구 현장에서\n필요한 능력을 배양할 수 있도록 합니다.현장 경험을 중시하며,\n이를 통해 학생들이 현실 세계에서 문제를 해결할 수 있는 역량을 키웁니다.',
            img: Manage3,
        },
        {
            title: '4. 지속 가능성과 사회적 책임 (Sustainability and Social Responsibility)',
            description: '지속 가능한 발전을 지향하며, 사회적 책임을 다하는 교육을 실천합니다. 환경과 사회적 문제에 대한 인식을 높이고, 이를 해결\n하기 위한 창의적이고 혁신적인 방법을 모색합니다. 학생들이 사회적 책임을 다하는 인재로 성장할 수 있도록 교육합니다.',
            img: Manage4,
        },
        {
            title: '5. 평생 학습과 성장 (Lifelong Learning and Growth)',
            description: '제약바이오산업연구소는 평생 학습의 중요성을 강조하며,\n학생들이 졸업 후에도 지속적으로 성장하고 발전할 수 있는 교육 환경을 제공합니다.\n끊임없는 자기 개발과 학습을 통해 변화하는 세상에 적응하고,\n새로운 기회를 창출할 수 있도록 지원합니다.',
            img: Manage5,
        },
    ]

    return (
        <Fragment>
            <motion.div className={'w-full bg-cover flex flex-col items-center justify-center h-[600px] relative'} style={{ backgroundImage: `url(${data[0].img})` }}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}>
                <div className='bg-[#00000080] py-6 px-8 w-[700px] absolute top-[50%] left-[50%]'>
                    <div className='flex-1 flex flex-col gap-8'>
                        <p className='text-32 text-[#FFC95F] text-left font-bold'>{data[0].title}</p>
                        <p className='text-xl text-[#FFFFFF] text-left font-medium'>{data[0].description}</p>
                    </div>
                </div>
            </motion.div>

            <Container className='flex items-center ' style={{ height: 'calc(100vh - 120px)' }}>
                <div className='flex items-end gap-16.25'>
                    <motion.div className='flex-1 flex flex-col gap-8'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <p className='text-32 text-black font-bold text-right whitespace-pre-line'>{data[1].title}</p>
                        <p className='text-xl text-black font-medium text-right'>{data[1].description}</p>
                    </motion.div>
                    <motion.div className='flex-1'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <img src={data[1].img} alt="manage1" />
                    </motion.div>
                </div>
            </Container>

            <Container className='flex items-center relative' style={{ height: 'calc(100vh - 120px)' }}>
                <motion.div className='flex-1'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <img src={data[2].img} alt="manage1" className='max-w-[853px]' />
                </motion.div>
                <div className='flex-2'></div>
                <motion.div className='flex-1 flex flex-col gap-8 bg-[#FFFFFFCC] p-[1rem] absolute top-[50%] left-[50%]'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <p className='text-32 text-black font-bold text-left whitespace-pre-line'>{data[2].title}</p>
                    <p className='text-xl text-black font-medium text-left whitespace-pre-line'>{data[2].description}</p>
                </motion.div>
            </Container>

            <Container className='flex flex-col items-center justify-center gap-30' style={{ height: 'calc(100vh - 120px)' }}>
                <motion.div
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <img src={data[3].img} alt="manage4" />
                </motion.div>
                <motion.div className='flex flex-col gap-8'
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <p className='text-32 text-black font-bold text-center'>{data[3].title}</p>
                    <p className='text-xl text-black font-medium text-center whitespace-pre-line'>{data[3].description}</p>
                </motion.div>
            </Container>

            <div
                id='intro_introduce_first'
                className={'w-full bg-cover py-33'}
                style={{ height: 'calc(100vh - 120px)', backgroundImage: `url(${data[4].img})` }}>
                <Container className='flex flex-col items-start gap-10'>
                    <motion.div
                        className='flex flex-col gap-8'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <p className='text-32 text-white font-bold text-left'>{data[4].title}</p>
                        <p className='text-xl text-white font-medium text-left whitespace-pre-line'>{data[4].description}</p>
                    </motion.div>

                    <motion.div
                        className='pl-6'
                        transition={{ delay: 0.1 }}
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}>
                        <Arrow />
                    </motion.div>

                    <ContactBtn delay={0.2} />
                </Container>
            </div>
        </Fragment>
    );
}

export default Bottom;

