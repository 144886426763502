import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Intro1 from 'assets/Intro/intro1.avif';
import Intro1Mo from 'assets/Intro/intro1_mo.avif';
import { GlobalContext } from 'App';

const First = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            id='intro_introduce_first'
            className={`w-full bg-cover flex flex-col items-center justify-center ${isTablet ? 'py-33' : 'py-33 h-[680px]'}`}
            style={{ backgroundImage: `url(${isTablet ? Intro1Mo : Intro1})` }}>
            <Container className='flex flex-col items-center'>
                <motion.button
                    className="bg-[#0D0D93B2] border border-white rounded-3xl py-1 px-4 mb-6 pointer-events-none text-xl text-white font-bold"
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    회사소개
                </motion.button>

                <motion.p
                    className={`${isTablet ? 'text-32' : 'text-40'} text-white font-bold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소
                </motion.p>


                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-white font-bold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    (Pharmaceutical{isTablet && <br />} & Bio Education Lab)
                </motion.p>
            </Container>
        </div>
    );
}

export default First;

