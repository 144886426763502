import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';

const Second = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <Container className='flex justify-center'>
            <div className={`flex flex-col gap-6 justify-center ${isTablet ? 'py-18 items-start' : 'py-30 items-center'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    프로그램 소개
                </motion.p>

                <motion.p
                    className={`${isTablet ? 'text-base text-left leading-7' : 'text-xl text-center leading-10'} text-black font-medium`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소의 임상 & 비임상 교육 프로그램은 제약바이오 산업에서 필수적인<br />
                    임상 및 비임상 시험의 전반적인 이해와 실무 능력을 키울 수 있도록 설계된 전문 과정입니다.<br />
                    비임상 시험의 기본 개념부터 GLP 준수, 독성 및 약리 시험 설계, 데이터 해석까지 폭넓게 다루며,<br />
                    임상시험 단계에서는 GCP 규정, 임상시험 설계, 데이터 관리 및 분석 방법을 학습할 수 있도록 돕겠습니다.
                </motion.p>
            </div>
        </Container>
    );
}

export default Second;

