import React, { Fragment, useCallback, useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const Third = () => {
    interface Data {
        count: number,
        title: string,
        subTitle: string,
        contents: string
    }

    const { isTablet } = useContext(GlobalContext);

    const data1: Data[] = [
        {
            count: 1, title: '기본 개념', subTitle: '제약산업 현황', contents: '제약산업의 특성, 국내외 제약산업 현황 등 산업 전반에 대한 이해'
        },
        {
            count: 2, title: '기본 개념', subTitle: '의약품 개발 전주기의 이해', contents: '의약품 개발 전주기에 대한 이해와 인허가가 차지하는 부분에 대한 이해'
        },
    ]

    const data2: Data[] = [
        {
            count: 3, title: '규제 관련\n법령', subTitle: '약사법관련 법령의 이해', contents: '규제기관이 요구하는 의약품 인허가 및 관리제도에 대한 이해'
        },
        {
            count: 4, title: '규제 관련\n법령', subTitle: '의약품 인허가 및 관리제도', contents: '의약품의 품목허가(신고) 심사 규정에 대한 이해'
        },
    ]

    const data3: Data[] = [
        {
            count: 5, title: '허가\n및\n심사', subTitle: '의약품 동등성 시험의 이해', contents: '의약품 개발 시 동등성을 입증하기 위해 생체 외에서 수행하는 시험 및 자료에 대한 이해'
        },
        {
            count: 6, title: '허가\n및\n심사', subTitle: '안전성 ･유효성 심사의 이해', contents: '의약품의 안전성과 유효성 심사를 받기 위해 요구되는 시험 및 자료에 대한 이해'
        },
        {
            count: 7, title: '허가\n및\n심사', subTitle: '기준 및 시험방법,\n안정성 시험의 이해', contents: '의약품의 품질 관리에 대한 심사를 받기위해 요구되는 시험 및 자료에 대한 이해'
        },
        {
            count: 8, title: '허가\n및\n심사', subTitle: '허가특허 연계제도의 이해', contents: '허가특허 연계제도의 이해\n특허권, 판매금지, 우선판매품목허가.등특허와 연계된 허가제도에 대한 이해'
        },
    ]

    const data4: Data[] = [
        {
            count: 9, title: '사후관리', subTitle: '사후 관리', contents: '시판 후 안전관리, 회수, GMP조사 등 품목허가 후 사후관리에 대한 이해'
        },
    ]

    const DataGrid = useCallback(({ data }: any) => (
        <motion.div
            className='grid grid-cols-[1fr_1fr_2fr_4.5fr] gap-x-1 gap-y-1.5 mb-[1rem]'
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={VIEWPORT_PROPERTY}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        {idx === 0 && (
                            <p className={`${gridInnerStyle(0)} border-b-[#D9E2F4] py-6 text-left font-bold bg-[#F7F8FF]`}
                                style={{ gridRow: setRowSpan(data, item.title) }}>
                                {item.title}
                            </p>
                        )}
                        <p className={`${gridInnerStyle(0)} border-b-[#E3EDF4] py-6 font-bold`}>{item.count}</p>
                        <p className={`${gridInnerStyle(1)} border-b-[#E3EDF4] py-6 px-8 text-left font-bold`}>{item.subTitle}</p>
                        <p className={`${gridInnerStyle(1)} border-b-[#E3EDF4] py-6 px-8 text-left font-semibold`}>{item.contents}</p>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])

    const DataGridMo = ({ data }: any) => (
        <motion.div
            className={`flex flex-col mb-[1rem]`}
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={{ once: true, amount: 0.1 }}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        {idx === 0 && (
                            <p className={`text-xl text-black  border-b-4 border-b-[#D9E2F4] p-4 text-left font-bold bg-[#DBE7F9] mb-4`}>
                                {item.title}
                            </p>
                        )}

                        <div className='flex w-full items-center justify-start bg-[#F1F6FA] gap-4'>
                            <p className={`text-base text-black border-b-0 py-2 pl-4 font-bold`}>{item.count}</p>
                            <p className={`text-base text-black border-b-0 py-2 text-left font-bold whitespace-pre-line`}>{item.subTitle}</p>
                        </div>

                        <p className={`text-black border-b-4 text-sm border-b-[#E3EDF4] py-2 px-4 text-left font-semibold mb-2`}>{item.contents}</p>
                    </Fragment>
                ))
            }
        </motion.div>
    );


    const gridInnerStyle = (num: number) => {
        return `flex ${num === 0 ? 'justify-center' : 'justify-start'} items-center border-b-4 whitespace-pre-line text-black text-xl`
    }

    const gridTitleStyle = () => {
        return `bg-[#DBE7F9] border-b-4 border-b-[#C8D0E5] text-xl p-1.5 text-black`
    }

    const setRowSpan = (data: Data[], title: string) => {
        const filterArr = data.filter(arr => arr.title === title)
        return `span ${filterArr.length}`
    }
    return (
        <Container className={`flex flex-col ${isTablet ? 'gap-6' : 'gap-15'}`}>
            <motion.p
                className={`${isTablet ? 'text-2xl leading-10' : 'text-48'} text-black font-extrabold text-left`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                인허가 교육{isTablet && <br />} 커리큘럼
            </motion.p>

            <div>
                {
                    !isTablet &&
                    <motion.div
                        className='grid grid-cols-[1fr_1fr_2fr_4.5fr] gap-x-1 mb-[1rem]'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <p className={`${gridTitleStyle()} text-center`}>분야</p>
                        <p className={`${gridTitleStyle()} text-center`}>차시</p>
                        <p className={`${gridTitleStyle()} text-left pl-8`}>교과목명</p>
                        <p className={`${gridTitleStyle()} text-left pl-8`}>학습내용</p>
                    </motion.div>
                }

                {
                    isTablet ?
                        <>
                            <DataGridMo data={data1} />
                            <DataGridMo data={data2} />
                            <DataGridMo data={data3} />
                            <DataGridMo data={data4} />
                        </> :
                        <>
                            <DataGrid data={data1} />
                            <DataGrid data={data2} />
                            <DataGrid data={data3} />
                            <DataGrid data={data4} />
                        </>
                }

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>

                <motion.div
                    className={isTablet ? 'py-18' : 'py-44'}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </div>

        </Container>
    );
}

export default Third;
