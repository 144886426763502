import React, { useContext } from 'react';
import Main5 from 'assets/Main/main5.avif';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';
import ContactBtn from 'components/Common/ContactBtn';

const Fourth = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="main_fourth" className="h-full">
            <div
                className={`w-full ${isTablet ? 'py-16' : 'h-[810px]'} bg-cover bg-bottom flex flex-col items-center justify-center ${isTablet ? 'gap-8' : 'gap-15'}`}
                style={{
                    backgroundImage: `url(${Main5})`,
                }}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-40'} text-white font-bold leading-10`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    제약바이오산업연구소의{isTablet && <br />} 고품격 교육이 궁금하다면?
                </motion.p>

                <ContactBtn />
            </div>
        </section>
    );
}

export default Fourth;

