import React, { Fragment, useCallback, useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import ContactBtn from 'components/Common/ContactBtn';
import { GlobalContext } from 'App';

const Third = () => {
    interface Data {
        count: number,
        title: string,
        subTitle: string,
        contents: string
    }

    const { isTablet } = useContext(GlobalContext);

    const data1: Data[] = [
        {
            count: 1, title: '기본 개념', subTitle: '국민건강보험법과\n건강보험 정책의 이해', contents: '국민건강보험법과 건강보험정책의 이해'
        },
        {
            count: 2, title: '기본 개념', subTitle: '보험의약품 가격 정책 전반의 이해', contents: '약제 급여목록표와 약제 상한금액 및 급여 상대가치점수에 대한 이해'
        },
        {
            count: 3, title: '기본 개념', subTitle: '의약품 경제성 평가 및 의학통계 기초', contents: '의약품 경제성 평가에서 효과에 대한 주요 자료원인 임상시험에 대한 \n이해를 높이기 위한 의학통계 개념 소개'
        },
    ]

    const data2: Data[] = [
        {
            count: 4, title: '보험(신약)\n등재', subTitle: '신약 등 협상대상 약제의 규정과 절차', contents: '신약 허가 관련 건강보험 등재 신청 관련 규정과 절차 및 경평에 대한 이해'
        },
        {
            count: 5, title: '보험(신약)\n등재', subTitle: '약제 결정, 조정기준 및 자료제출\n/ 제네릭의약품의 산정 및 등재', contents: '신규 약제 결정 관련 규정 및 조정 기준의 이해'
        },
        {
            count: 6, title: '보험(신약)\n등재', subTitle: '약가 협상에 관한 이해', contents: '우리나라 약가 제도 및 약가 협상 제도에 대한 이해'
        },
    ]

    const data3: Data[] = [
        {
            count: 7, title: '약재 결정', subTitle: '급여기준 확대에 대한 이해', contents: '약제 급여기준 확대와 절차의 이해'
        },
        {
            count: 8, title: '약재 결정', subTitle: '사후관리제도의 이해', contents: '사후관리 제도와 대응의 이해(PV, 실거래가)'
        },
        {
            count: 9, title: '약재 결정', subTitle: '재평가 제도에 대한 이해', contents: '약가 재평가 제도와 종류의 이해'
        },
        {
            count: 10, title: '약재 결정', subTitle: '퇴장방지제도 \n및 조정신청 제도의 이해', contents: '퇴장방지 의약품과 조정신청 절차에 대한 이해'
        },
    ]

    const DataGrid = useCallback(({ data }: any) => (
        <motion.div
            className={`grid grid-cols-[1fr_1fr_2fr_4.5fr] gap-x-1 gap-y-1.5 mb-[1rem]`}
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={VIEWPORT_PROPERTY}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        {idx === 0 && (
                            <p className={`${gridInnerStyle(0)} border-b-[#D9E2F4] py-6 text-left font-bold bg-[#F7F8FF]`}
                                style={{ gridRow: setRowSpan(data, item.title) }}>
                                {item.title}
                            </p>
                        )}
                        <p className={`${gridInnerStyle(0)} border-b-[#E3EDF4] py-6 font-bold`}>{item.count}</p>
                        <p className={`${gridInnerStyle(1)} border-b-[#E3EDF4] py-6 pl-8 text-left font-bold`}>{item.subTitle}</p>
                        <p className={`${gridInnerStyle(1)} border-b-[#E3EDF4] py-6 px-8 text-left font-semibold`}>{item.contents}</p>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])

    const DataGridMo = useCallback(({ data }: any) => (
        <motion.div
            className={`flex flex-col mb-[1rem]`}
            initial={START_ANIMATION_PROPERTY}
            whileInView={END_ANIMATION_PROPERTY}
            viewport={{ once: true, amount: 0.1 }}
        >
            {
                data.map((item: Data, idx: number) => (
                    <Fragment key={idx}>
                        {idx === 0 && (
                            <p className={`text-xl text-black  border-b-4 border-b-[#D9E2F4] p-4 text-left font-bold bg-[#DBE7F9] mb-4`}>
                                {item.title}
                            </p>
                        )}

                        <div className='flex w-full items-center justify-start bg-[#F1F6FA] gap-4'>
                            <p className={`text-base text-black border-b-0 py-2 pl-4 font-bold`}>{item.count}</p>
                            <p className={`text-base text-black border-b-0 py-2 text-left font-bold whitespace-pre-line`}>{item.subTitle}</p>
                        </div>

                        <p className={`text-black border-b-4 text-sm border-b-[#E3EDF4] py-2 px-4 text-left font-semibold mb-2`}>{item.contents}</p>
                    </Fragment>
                ))
            }
        </motion.div>
    ), [])


    const gridInnerStyle = (num: number) => {
        return `flex ${num === 0 ? 'justify-center' : 'justify-start'} items-center border-b-4 whitespace-pre-line text-black text-xl`
    }

    const gridTitleStyle = () => {
        return `bg-[#DBE7F9] border-b-4 border-b-[#C8D0E5] text-xl p-1.5 text-black`
    }

    const setRowSpan = (data: Data[], title: string) => {
        const filterArr = data.filter(arr => arr.title === title)
        return `span ${filterArr.length}`
    }
    return (
        <Container className={`flex flex-col ${isTablet ? 'gap-6' : 'gap-15'}`}>
            <motion.p
                className={`${isTablet ? 'text-2xl leading-10' : 'text-48'} text-black font-extrabold text-left`}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >
                의약품 약가 교육{isTablet && <br />}  커리큘럼
            </motion.p>

            <div>
                {
                    !isTablet &&
                    <motion.div
                        className='grid grid-cols-[1fr_1fr_2fr_4.5fr] gap-x-1 mb-[1rem]'
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <p className={`${gridTitleStyle()} text-center`}>분야</p>
                        <p className={`${gridTitleStyle()} text-center`}>차시</p>
                        <p className={`${gridTitleStyle()} text-left pl-8`}>교과목명</p>
                        <p className={`${gridTitleStyle()} text-left pl-8`}>학습내용</p>
                    </motion.div>
                }

                {
                    isTablet ?
                        <>
                            <DataGridMo data={data1} />
                            <DataGridMo data={data2} />
                            <DataGridMo data={data3} />
                        </> :
                        <>
                            <DataGrid data={data1} />
                            <DataGrid data={data2} />
                            <DataGrid data={data3} />
                        </>
                }

                <motion.p
                    className={`text-[#0000004D] font-medium ${isTablet ? 'text-xs' : 'text-xl '} text-right`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    ※ 상황에 따라 커리큘럼에 변경사항이 생길 수 있습니다.
                </motion.p>

                <motion.div
                    className={isTablet ? 'py-18' : 'py-44'}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}>
                    <ContactBtn bg='bg-[#0D0D93]' />
                </motion.div>
            </div>

        </Container>
    );
}

export default Third;
