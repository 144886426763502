import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Intro4 from 'assets/Intro/intro4.avif';
import Intro4Mo from 'assets/Intro/intro4_mo.avif';
import { GlobalContext } from 'App';

const Fifth = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div className='flex flex-col items-center justify-between'
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>
            <motion.div
                className={`${isTablet && 'mb-8'}`}
                transition={{ delay: 0.1 }}
                initial={START_ANIMATION_PROPERTY}
                whileInView={END_ANIMATION_PROPERTY}
                viewport={VIEWPORT_PROPERTY}
            >

                <img src={isTablet ? Intro4Mo : Intro4} alt="Intro4" />
            </motion.div>
            <div className={`flex flex-col gap-8 ${isTablet && 'px-4'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-32'} text-black font-bold text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    미래를 준비하는 교육 비전
                </motion.p>
                <motion.p
                    className={`${isTablet ? 'text-base leading-7' : 'text-2xl leading-10'} text-black font-medium  text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 단순히 지식을 전달하는 것을 넘어{!isTablet && <br />}
                    고객들이 미래의 바이오 산업을 이끌어갈 창의적이고 혁신적인 리더로 성장할 수 있는{!isTablet && <br />}
                    잠재력을 최대한 발휘할 수  있도록 지원하는 것을 목표로 합니다.
                </motion.p>
            </div>

        </div>
    );
}

export default Fifth;

