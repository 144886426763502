import React, { useContext } from 'react';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import Program2 from 'assets/Employed/Program/program2.avif';
import Program2Mo from 'assets/Employed/Program/program2_mo.avif';
import { GlobalContext } from 'App';

const Third = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <div
            className={`flex flex-col items-center justify-center bg-[#F9F9F9]`}
            style={{ height: isTablet ? 'auto' : 'calc(100vh - 120px)' }}>
            <Container className={`flex flex-col ${isTablet ? 'pt-14 pb-18 px-4 gap-15' : 'gap-21'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl' : 'text-48'} text-center text-black font-extrabold`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소 교육 플로우
                </motion.p>

                <motion.div
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={{ once: true, amount: 0.3 }}
                >
                    <img src={isTablet ? Program2Mo : Program2} alt="Program2"
                        className={`${isTablet && 'px-4'}`} />
                </motion.div>
            </Container>
        </div>
    );
}

export default Third;

